import { formatDate } from "../../../utils/formatting";
import CostTooltip from "./CostTooltip";
import QuantityTooltip from "./QuantityTooltip";

const CustomerBilling = ({ customers, nestingLevel, pricing, pup, batchSize }) => {
	const leftMargin = (nestingLevel - 1) * 30;
	const customerTitleText = pup ? "Total Seats" : `Batches of ${batchSize} Seats`;

	return (
		<div className="billing-customer">
			<div className="billing-customer-header" style={{ marginLeft: `${leftMargin}px` }}>
				<p className="font_xs_bold">Name</p>
				<p className="font_xs_bold">{customerTitleText}</p>
				<p className="font_xs_bold">Date Provisioned</p>
				<p className="font_xs_bold">Date Deleted</p>
				<p className="font_xs_bold">Quantity</p>
				<p className="font_xs_bold">Cost</p>
			</div>
			{customers.map(item => (
				<div className="billing-customer-grid" key={item.Name} style={{ marginLeft: `${leftMargin}px` }}>
					<h5>{item.Name}</h5>
					<p className="font_xs">{item.CustomerCount}</p>
					<p className="font_xs">{formatDate(item.DateProvisioned)}</p>
					<p className="font_xs">{formatDate(item.DateDeleted) || 'N/A'}</p>
					<p className="font_xs">{item.Quantity} {QuantityTooltip(item.Quantity, item.CustomerCount, pup)}</p>
					<p className="font_xs">{item.Cost} {CostTooltip(item.Quantity, pricing)}</p>
				</div>
			))}
		</div>
	);
};

export default CustomerBilling;