import { useCallback, useRef, useState } from "react";
import { getFetchOptions } from "../utils/fetch.js";
import { useSession, LogoutReason } from "../features/Auth/hooks/useSession";
import useAccessToken from "../features/Auth/hooks/useAccessToken.js";

/**
 * @typedef {Object} FetchState
 * @property {boolean} ready
 * @property {boolean} loading
 * @property {{status: number, message: string}} error
 * @property {string} data
 * @property {Function} execute
 */

/**
 * This hook wraps the fetch API and is used to perform requests to the server on demand, typically POST, PUT and DELETE requests.
 * @returns {FetchState} State data.
 */
const useFetch = () => {
	const { reason } = useSession();
  const { accessToken } = useAccessToken();
  const [loading, setLoading] = useState(false);
  const error = useRef(null);
  const [data, setData] = useState(null);

	const execute = useCallback(async (url, options, onComplete) => {
		if ((reason !== LogoutReason.Expired) && (accessToken)) {
      const performFetch = async () => {
        error.current = null;
        setLoading(true);
        if (!options) {
          options = getFetchOptions();
        }
        options.headers.Authorization = "Bearer " + accessToken;
        const response = await fetch(url, options);
        let content = await response.text();
        try {
          content = JSON.parse(content);
        }
        catch (ex) { /* Ignore... it will be an empty string */ }
        if ((response.status >= 200) && (response.status < 300)) {
          setData(content);
        }
        else {
          error.current = {
            status: response.status,
            message: content
          };
        }
        setLoading(false);
        if (onComplete) {
          onComplete(error.current, content);
        }
      };
      performFetch();
    }
  }, [accessToken, reason, setData]);

  return {
    ready: accessToken !== null,
    executingFetch: loading,
    error: error.current,
    data,
		execute
  };
};

export default useFetch;