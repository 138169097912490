import { useEffect } from "react";
import BusinessInputs from "../../../components/BusinessInputs";
import InputTextWithValidation from "../../../components/InputTextWithValidation";

const Details = ({ businessName, businessAddress, userFirstName, userLastName, userEmail, onChangeInput, setCanSubmit }) => {
	useEffect(() => {
		setCanSubmit(
			(businessName.value.trim().length > 0) &&
			(businessAddress.value.trim().length > 0) &&
			(userFirstName.value.trim().length > 0) &&
			(userLastName.value.trim().length > 0));
	}, [businessName.value, businessAddress.value, userFirstName.value, userLastName.value, setCanSubmit]);

	return (
		<div>
			<p>These details will be visible to your suppliers. Please ensure the details below are correct.</p>
			<div className="form-row">
				<div>
					<p>Your email</p>
				</div>
					<p className="tmd-edit-value-text">{userEmail}</p>
			</div>
			<div className="form-row">
				<label className="ap-label" htmlFor="firstName">Your name</label>
				<div>
					<InputTextWithValidation
						id="firstName"
						data={userFirstName}
						placeholder="First Name"
						maxLength="100"
						onChange={e => onChangeInput({ userFirstName: { value: e.target.value, validation: 0 } })}
						feedback="The first name contains invalid characters."
					/>
				</div>
				<div>
					<InputTextWithValidation
						id="lastName"
						data={userLastName}
						placeholder="Last Name"
						maxLength="100"
						onChange={e => onChangeInput({ userLastName: { value: e.target.value, validation: 0 } })}
						feedback="The last name contains invalid characters."
					/>
				</div>
			</div>
			<BusinessInputs
				nameLabel="Trading Name"
				name={businessName}
				addressLabel="Business Address"
				address={businessAddress}
				onChangeInput={onChangeInput}
			/>
		</div>
	);
};

export default Details;