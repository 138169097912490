import { formatPrice } from "../../../utils/formatting";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faPencil, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { TYPES } from "../../../utils/resellerPackageUtils";

const PackageDataRow = ({ index, packageName, pup, seats, sizeseats, bronze, silver, gold, hidePrices, hasEdit, onClickEdit, hasDelete, onClickDelete }) => {
	function setPriceItem(value) {
		if (!hidePrices) {
			return "\u00A3" + formatPrice(value);
		}
		else {
			return (<FontAwesomeIcon icon={faCheck} />);
		}
	}

	return (
			<div className={` ${hasEdit || hasDelete ? "package-row-editable" : "package-row"} grey-row`}>
				<p>{packageName}</p>
				<p>{TYPES[pup]}</p>
				<p>{seats}</p>
				<p>{setPriceItem(bronze)}</p>
				<p>{setPriceItem(silver)}</p>
				<p>{setPriceItem(gold)}</p>
				{hasEdit && (
					<>
						{onClickEdit && (
							<button
								className="icon-btn primary"
								onClick={() => onClickEdit(index)}
								type="button"
							>
								<FontAwesomeIcon icon={faPencil} />
							</button>
						)}
					</>
				)}
				{hasDelete && (
					<>
						{onClickDelete && (
							<button
								className="icon-btn itelBlue"
								onClick={() => onClickDelete(index)}
								type="button"
							>
								<FontAwesomeIcon icon={faTrashCan} />
							</button>
						)}
					</>
				)}
			</div>
	);

};

export default PackageDataRow;