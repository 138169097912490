import { useState } from "react";
import { faCirclePlus } from "@fortawesome/free-solid-svg-icons";
import { formatDate } from "../../utils/formatting";
import DataTable from "../../components/DataTable";
import Button, { ButtonType } from "../../components/Button";
import useFetchAuto from "../../hooks/useFetchAuto";
import { useSession, LogoutReason } from "../Auth/hooks/useSession";
import AuthLogoutMessage from "../Auth/components/AuthLogoutMessage";
import LoadingSpinner from "../../components/loadingSpinner/LoadingSpinner";
import AddMember from "./AddMember";
import ViewMember from "./ViewMember";
import EditMember from "./EditMember";
import "./members.css";

const ListMembers = () => {
	const { reason } = useSession();
	const [showAddModal, setAddModal] = useState(false);
	const [showViewModal, setViewModal] = useState(false);
	const [editing, setEditing] = useState(false);
	const [viewItemData, setViewItemData] = useState({});
	const [fetch, setFetch] = useState(true);
	const { loading, data } = useFetchAuto("/api/Members/", null, fetch);

	function onClickTeamMember(adding, item) {
		if (adding) {
			setAddModal(true);
		}
		else {
			setEditing(false);
			setViewItemData(item.data);
			setViewModal(true);
		}
	}

	const columns = [
		{
			field: "FirstName",
			headerName: "Name",
			valueGetter: (data) => { return data.FirstName + " " + data.LastName; }
		},
		{
			field: "Email",
			headerName: "Email",
		},
		{
			field: "CreationDate",
			headerName: "Date Created",
			valueGetter: (data) => { return formatDate(data.CreationDate); }
		},
		{
			field: "LastLoginDate",
			headerName: "Last Login",
			valueGetter: (data) => { return data.Registration ? <em className="grey">Not Registered</em> : formatDate(data.LastLoginDate); }
		}
	];

	return (
		<div>
			{(reason !== LogoutReason.None)
				? <AuthLogoutMessage reason={reason} />
				: (<>
					<h1>My Team</h1>
					<p className="mb-6">Manage your team - add or remove access to the Admin Portal to those who need it across your organisation.</p>
					{loading
						? <LoadingSpinner active={loading} />
						: <DataTable columns={columns} rows={data} columnIdName="Id" onRowClick={(params) => onClickTeamMember(false, params)} />
					}
					<Button type={ButtonType.Secondary} icon={faCirclePlus} label={"Add New Team Member"} onClick={() => onClickTeamMember(true)} />
					{showAddModal && <AddMember showModal={showAddModal} setModal={setAddModal} setFetchParentData={setFetch} />}
					{showViewModal &&
						(editing
							? <EditMember open={editing} setOpenEditor={setEditing} showModal={showViewModal} setModal={setViewModal} item={viewItemData} setItem={setViewItemData} />
							: <ViewMember
								open={!editing}
								setOpenEditor={setEditing}
								showModal={showViewModal}
								setModal={setViewModal}
								item={viewItemData}
								setFetchParentData={setFetch}
							/>
						)
					}
				</>)
			}
		</div>
	);
};

export default ListMembers;