import { useState } from "react";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { useSession, LogoutReason } from "../Auth/hooks/useSession";
import AuthLogoutMessage from "../Auth/components/AuthLogoutMessage";
import { getFetchOptions } from "../../utils/fetch";
import useFetch from '../../hooks/useFetch';
import Modal from "../../components/modals/Modal";
import MessageModal from "../../components/modals/MessageModal";
import MemberDetails from "./components/MemberDetails";
import LoadingSpinner from "../../components/loadingSpinner/LoadingSpinner";
import Button, { ButtonType } from "../../components/Button";
import Alert, { AlertType } from "../../components/Alert";

const EditMember = (props) => {
	const INITIAL_DATA = {
		userFirstName: { value: props.item.FirstName, validation: 0 },
		userLastName: { value: props.item.LastName, validation: 0 },
		userEmail: { value: props.item.Email, validation: 0 }
	};

	const { reason } = useSession();
	const [inputValues, setInputValues] = useState(INITIAL_DATA);
	const [canSubmit, setCanSubmit] = useState(false);
	const { execute, executingFetch, error } = useFetch();

	function onChangeInput(fields) {
		setInputValues((prev) => {
			return { ...prev, ...fields }
		});
	}

	function onClickConfirm(enable) {
		const options = getFetchOptions({
			method: "PUT",
			body: {
				B2CId: props.item.B2CId,
				FirstName: inputValues.userFirstName.value,
				LastName: inputValues.userLastName.value,
				Email: inputValues.userEmail.value,
				ResellerId: props.item.ResellerId
			}
		});
		execute("/api/Member", options, (error) => {
			if (error) {
				console.error(error.message);
				alert(error.message);
			}
			props.setItem({
				...props.item,
				FirstName: inputValues.userFirstName.value,
				LastName: inputValues.userLastName.value,
				Email: inputValues.userEmail.value
			});
			enable();
			props.setOpenEditor(false);
		});
	}

	function onClickCancel() {
		props.setOpenEditor(false);
	}

	function onCloseErrorModal() {
		window.location.reload();
	}

	const content = (
		<div>
			<h2>Team Member Details</h2>
			<MemberDetails {...inputValues} initialData={INITIAL_DATA} onChangeInput={onChangeInput} setCanSubmit={setCanSubmit} title={"Edit"} />
		</div>
	);

	const buttons = (
		<>
			<Button type={ButtonType.Tertiary} label={"Cancel"} onClick={onClickCancel} />
			<Button
				type={ButtonType.Primary}
				disabled={!canSubmit}
				icon={faCircleCheck}
				label={"Confirm"}
				onClick={(e, c) => onClickConfirm(c)}
				disableUntilClickCompleted={true}
			/>
		</>
	);

	const ErrorModal = (
		<>
			{error &&
				<MessageModal title="Cannot edit Team Member" onClick={onCloseErrorModal} size="m">
					<Alert type={AlertType.Error}>{error.message}</Alert>
				</MessageModal>
			}
		</>
	);

	const EditModal = (
		<Modal
			title="Edit Team Member Details"
			showModal={props.showModal}
			content={content}
			size="m"
			modalButtons={buttons}
			close={onClickCancel}
		/>
	);

	const render = () => {
		if (reason !== LogoutReason.None) {
			return <AuthLogoutMessage reason={reason} />;
		}
		if (executingFetch) {
			return <LoadingSpinner active={executingFetch} />;
		}
		if (props.open) {
			if (error) {
				return ErrorModal;
			}
			return EditModal;
		}
		return <></>;
	}

	return (
		<>{render()}</>
	);
};

export default EditMember;