import { LoadingProvider } from "./components/loadingSpinner/LoadingSpinner";
import Pages from "./components/Pages";
import DynamicConfigMsalProvider from "./features/Auth/components/DynamicConfigMsalProvider";

const App = () => {
	return (
		<LoadingProvider>
			<DynamicConfigMsalProvider>
				<Pages />
			</DynamicConfigMsalProvider>
		</LoadingProvider>
  );
};

export default App;