import React, { useState } from 'react';
import { Dropdown } from 'primereact/dropdown';
import useFetchAuto from '../../hooks/useFetchAuto';
import LoadingSpinner from '../../components/loadingSpinner/LoadingSpinner';

const SelectReseller = ({ onSelectReseller }) => {
	const [selectedReseller, setSelectedReseller] = useState(null);
	const { loading, data } = useFetchAuto("/api/Resellers/", null, setSelectedReseller);

	const handleChange = (e) => {
		setSelectedReseller(e.value);
		if (e.value) {
			onSelectReseller(e.value);
		}
	}

	return (
		<>
			<h2>Select Reseller</h2>
			<div className="mb-4 invoiceGrid">
				<label>Search for Reseller</label>
				{loading
					? <LoadingSpinner active={loading} />
					: (<Dropdown value={selectedReseller} onChange={handleChange} options={data} optionLabel="Name" placeholder="Select a Reseller" filter />)
				}
			</div>
		</>
	);
}

export default SelectReseller;
