import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";

const QuantityTooltip = (quantity, customerCount, pup) => {

	const calculatedQuantity = Math.round((quantity / customerCount) * 30);
	const tooltipText = pup ? `Total Seats * Fraction of the Month (${customerCount} * ${calculatedQuantity} / 30)` : `Batches * Fraction of the Month (${customerCount} * ${calculatedQuantity} / 30)`;

	return (
		<span className="tooltip">
			<span className="tooltip-icon"><FontAwesomeIcon icon={faCircleInfo} />
				<span className="tooltip-text">{tooltipText}</span>
			</span>

		</span>
	);
};

export default QuantityTooltip;