import Home from "./features/Home/Home";
import ListResellers from "./features/Resellers/ListResellers";
import ListMembers from "./features/Team/ListMembers";
import ListCustomers from "./features/Customers/ListCustomers";
import ListInvoices from "./features/Billing/ListInvoices";
import BillingReport from "./features/Reports/BillingReport";
import Provisioning from "./features/Provisioning/Provisioning"

const AppRoutes = [
	{
		index: true,
		path: "/",
		element: <Home />
	},
	{
		path: "/resellers",
		element: <ListResellers />
	},
	{
		path: "/teams",
		element: <ListMembers />
	},
	{
		path: "/customers",
		element: <ListCustomers />
	},
	{
		path: "/billing",
		element: <ListInvoices />
	},
	{
		path: "/reports",
		element: <BillingReport />
	},
	{
		path: "/provisioning",
		element: <Provisioning />
	}
];

export default AppRoutes;
