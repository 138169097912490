import { useEffect } from "react";
import Row from "../../../components/Row";

const CustomerSummary = ({ businessName, businessAddress, userFirstName, userLastName, userEmail, packageItem, packageData, setCanSubmit }) => {
	useEffect(() => {
		setCanSubmit(true);
	}, [setCanSubmit]);
	
	const packageLink = packageData.find((i) => { return i.ParentId === packageItem.Id; });
	const getBusinessAddressText = () => {
		return businessAddress.value.replace(/\n/g, "<br/>");
	}

	const getPackageInformation = () => {
		if (packageLink) {
			if (packageItem.EndUser) {
				const getPcpSeats = () => {
					let result = (packageItem.Seats * packageLink.Seats) + " seats";
					if (packageItem.Seats > 1) {
						return [result, " (", packageItem.Seats, " batches of ", packageLink.Seats, " PCP)"].join("");
					}
					else {
						result += " (PCP)";
					}
					return result;
				};
				const seats = (!packageLink.PUP) ? getPcpSeats() : packageItem.Seats + " seats (PUP)";
				return <>{packageLink.PackageName}<br />{seats}</>;
			}
			else {
				return <>{packageLink.PackageName}</>;
			}
		}
		return "";
	};

	const getBillingText = () => {
		if ((packageLink) && (packageLink.ResellerName.length)) {
			let result = `iTEL will bill ${packageLink.ResellerName} for this customer.`;
			return result;
		}
		return "";
	};

	return (
		<div>
			<p>
				You are adding a new Customer with the following details. Please make sure the details are correct. <strong>{getBillingText()}</strong><br /><br />
				<strong>{userFirstName.value} {userLastName.value}</strong> will receive an email at {userEmail.value} inviting them to join the ucSKILLS platform.
			</p>
			<Row class="my-4">
				<div className="ap-col col-2">
					<h4 className="no-margin">Main Contact</h4>
				</div>
				<div className="ap-col"><p>{userFirstName.value} {userLastName.value}<br />{userEmail.value}</p></div>
			</Row>
			<Row class="mb-4">
				<div className="ap-col col-2">
					<h4 className="no-margin">Business</h4>
				</div>
				<div className="ap-col">
					<p>{businessName.value}<br /><span className="contents" dangerouslySetInnerHTML={{ __html: getBusinessAddressText() }}></span></p>
				</div>
			</Row>
			<Row class="mb-4">
				<div className="ap-col col-2">
					<h4 className="no-margin">Package</h4>
				</div>
				<div className="ap-col font-s">
					<p>{getPackageInformation()}</p>
				</div>
			</Row>
		</div>
	);
};

export default CustomerSummary;