/**
	* Returns a formatted date string, considering null or empty values.
	* @param {?} value - Any nullable value.
	* @param {boolean} includeTime - TRUE if the time is included, FALSE otherwise.
	* @param {boolean} dashIfEmpty - TRUE if an empty time returns a dash, FALSE if an empty item remains blank.
	* @returns {string} A string formatted date, or a string based upon dashIfEmpty.
	*/
export function formatDate(value, includeTime = false, dashIfEmpty = true) {
	if ((value) && ((typeof (value) === "number") || ((value !== "null") && (value.trim().length)))) {
		let d = new Date(value);
		if (includeTime) {
			return d.toLocaleString('en-GB').replace(/\u200E/g, "");
		}
		else {
			return d.toLocaleDateString('en-GB').replace(/\u200E/g, "");
		}
	}
	return (dashIfEmpty) ? "-" : "";
}

export function formatDateWithHyphen(value, includeTime = false, dashIfEmpty = true) {
	if (!value) {
		return dashIfEmpty ? "-" : "";
	}

	// Create a Date object from the value
	let d = (typeof value === "string" || typeof value === "number") ? new Date(value) : value;

	// Ensure d is a valid Date object
	if (!(d instanceof Date) || isNaN(d.getTime())) {
		return dashIfEmpty ? "-" : "";
	}

	if (includeTime) {
		// Format with time
		return `${d.getFullYear()}-${String(d.getMonth() + 1).padStart(2, '0')}-${String(d.getDate()).padStart(2, '0')} ${String(d.getHours()).padStart(2, '0')}:${String(d.getMinutes()).padStart(2, '0')}:${String(d.getSeconds()).padStart(2, '0')}`;
	} else {
		// Format as YYYY-MM-DD
		return `${d.getFullYear()}-${String(d.getMonth() + 1).padStart(2, '0')}-${String(d.getDate()).padStart(2, '0')}`;
	}
}

export function formatInvoiceDate(value) {
	const d = value.getDate();
	const m = value.getMonth() + 1; // Months are zero-based in JavaScript
	const y = value.getFullYear();

	return [
		(d > 9 ? '' : '0') + d,
		(m > 9 ? '' : '0') + m,
		y
	].join('/');
}

/**
 * Returns a decimal number as a price value.
 * @param {?} value - Any nullable value.
 * @returns {string} A string formatted price, either with no decimal places or 2.
 */
export function formatPrice(value) {
	if (value) {
		return (Number.isInteger(Number(value))) ? value : Number(value).toFixed(2);
	}
	return "-";
}

export function formatAsCurrency(value) {
	return value.toLocaleString("en-UK", { style: "currency", currency: "GBP" });
};