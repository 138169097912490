import { useRef, useState } from "react";
import { faArrowRight, faCirclePlus } from "@fortawesome/free-solid-svg-icons";
import { useSession, LogoutReason } from "../Auth/hooks/useSession";
import AuthLogoutMessage from "../Auth/components/AuthLogoutMessage";
import { isNameValid, isEmailValid, isTextValid } from "../../utils/validation";
import { ResellerType } from "../../utils/getUserInformation";
import { getFetchOptions } from "../../utils/fetch";
import { getPackageId } from "../../utils/resellerPackageUtils";
import useFetch from "../../hooks/useFetch";
import useFetchAuto from "../../hooks/useFetchAuto";
import useMultiStepModal from "../../components/modals/hooks/useMultiStepModal";
import MultiStepModal from "../../components/modals/MultiStepModal";
import BillableResellerDetails from "./components/BillableResellerDetails";
import BillableResellerPackages from "./components/BillableResellerPackages";
import BillableResellerSummary from "./components/BillableResellerSummary";
import ResellerDetails from "./components/ResellerDetails";
import ResellerSummary from "./components/ResellerSummary";
import LoadingSpinner from "../../components/loadingSpinner/LoadingSpinner";
import MessageModal from "../../components/modals/MessageModal";
import Button, { ButtonType } from "../../components/Button";
import Alert, { AlertType } from "../../components/Alert";

const AddReseller = ({ setModal, showModal, setFetchParentData, master }) => {
	const INITIAL_DATA = useRef({
		businessName: { value: "", validation: 0 },
		businessAddress: { value: "", validation: 0 },
		userFirstName: { value: "", validation: 0 },
		userLastName: { value: "", validation: 0 },
		userEmail: { value: "", validation: 0 },
		packages: []
	});
	
	const { reason } = useSession();
	const toggle = () => setModal(!showModal);
	const [data, setData] = useState(INITIAL_DATA.current);
	const [existingReseller, setExistingReseller] = useState(false);
	const [canSubmit, setCanSubmit] = useState(false);
	const { loading, data: packageData } = useFetchAuto("/api/ListAssignablePackages/");
	const { execute, executingFetch, error } = useFetch();

	const { contents, content, step, back, next, last } = useMultiStepModal(
		(master) ? [
			<BillableResellerDetails {...data} onChangeInput={onChangeInput} setCanSubmit={setCanSubmit} title={"Reseller Details"} />,
			<BillableResellerPackages {...data} packageData={packageData} onChangeInput={onChangeInput} setCanSubmit={setCanSubmit} title={"Assign Packages"} />,
			<BillableResellerSummary {...data} packageData={packageData} setCanSubmit={setCanSubmit} title={"Summary"} />
		] : [
			<ResellerDetails {...data} packageData={packageData} onChangeInput={onChangeInput} setCanSubmit={setCanSubmit} title={"Reseller Details"} />,
			<ResellerSummary {...data} existingReseller={existingReseller} setCanSubmit={setCanSubmit} title={"Summary"} />
		]
	);

	/*
	 * Package Detail
	 *   From iTEL:
	 *     fields = { name, type, seats, bronze, silver, gold }, defined in BillableResellerPackages
	 *   From a Reseller:
	 *     fields = packageData items (ListAssignablePackagesDto)
	 * When creating a new package, it is a child of the ResellerPackage record where Id = name/ParentId
	 */
	function onChangeInput(fields) {
		setData((prev) => {
			return { ...prev, ...fields }
		});
	}

	function validateBillableResellerDetail() {
		const validations = [
			isTextValid(data.businessName.value) ? 2 : 1,
			isTextValid(data.businessAddress.value) ? 2 : 1,
			isNameValid(data.userFirstName.value) ? 2 : 1,
			isNameValid(data.userLastName.value) ? 2 : 1,
			isEmailValid(data.userEmail.value) ? 2 : 1
		];
		setData((prev) => {
			return {
				...prev,
				businessName: { value: prev.businessName.value, validation: validations[0] },
				businessAddress: { value: prev.businessAddress.value, validation: validations[1] },
				userFirstName: { value: prev.userFirstName.value, validation: validations[2] },
				userLastName: { value: prev.userLastName.value, validation: validations[3] },
				userEmail: { value: prev.userEmail.value, validation: validations[4] }
			};
		});
		return validations.every((value) => value === 2);
	}

	function validateResellerDetail() {
		const validations = [
			isNameValid(data.userFirstName.value) ? 2 : 1,
			isNameValid(data.userLastName.value) ? 2 : 1,
			isEmailValid(data.userEmail.value) ? 2 : 1
		];
		setData((prev) => {
			return {
				...prev,
				userFirstName: { value: prev.userFirstName.value, validation: validations[0] },
				userLastName: { value: prev.userLastName.value, validation: validations[1] },
				userEmail: { value: prev.userEmail.value, validation: validations[2] }
			};
		});
		return ((validations.every((value) => value === 2)) && (data.packages.length > 0));
	}

	function checkDetailsExist() {
		const options = getFetchOptions();
		execute("/api/ResellerByEmail/" + data.userEmail.value, options, (error, data) => {
			if (!error) {
				setExistingReseller(data === 1);
				if (data !== 1) {
					setCanSubmit(false);
					next();
				}
			}
			else {
				console.error(error.message);
			}
		});
	}

	function onClickNext() {
		let valid = false;
		if (step === 0) {
			valid = (master) ? validateBillableResellerDetail() : validateResellerDetail();
			if (valid) {
				checkDetailsExist();
			}
		}
		else {
			valid = true;
			setCanSubmit(false);
			next();
		}
	}

	function onClickSubmit(enable) {
		setFetchParentData(false);
		const packages = (master) ?
			data.packages.map((curr) => {
				return {
					ParentId: curr.name,
					PackageId: getPackageId(packageData, curr.name),
					PUP: curr.type === 0,
					Seats: Number(curr.seats),
					BronzePrice: Number(curr.bronze),
					SilverPrice: Number(curr.silver),
					GoldPrice: Number(curr.gold),
					PreExisting: existingReseller
				};
			}) :
			data.packages.map((curr) => {
				return {
					ParentId: curr.ParentId,
					PackageId: curr.PackageId,
					PUP: curr.PUP
				};
			});
		const options = getFetchOptions({
			method: "POST",
			body: {
				Name: data.businessName.value,
				Address: data.businessAddress.value,
				Type: (master) ? ResellerType.Billable : ResellerType.NonBillable,
				ContactFirstName: data.userFirstName.value,
				ContactLastName: data.userLastName.value,
				ContactEmail: data.userEmail.value,
				Packages: packages
			}
		});
		execute("/api/Reseller", options, (error) => {
			if (error) {
				console.error(error.message);
			}
			else {
				toggle();
				setFetchParentData(true);
			}
			enable();
		});
	}

	const modalButtons = (
		<>
			{(!last) ? (
				<Button type={ButtonType.Primary} disabled={!canSubmit} icon={faArrowRight} iconRight={true} label={"Next"} onClick={onClickNext} />
			) : (
					<Button
						type={ButtonType.Primary}
						disabled={!canSubmit}
						icon={faCirclePlus}
						label={"Add"}
						onClick={(e, c) => onClickSubmit(c)}
						disableUntilClickCompleted={true}
					/>
			)}
		</>
	);

	function onCloseErrorModal() {
		window.location.reload();
	}

	const FetchErrorModal = (
		<MessageModal title="Error" onClick={onCloseErrorModal} size="m">
			<Alert type={AlertType.Error}>{error && error.message}</Alert>
		</MessageModal>
	);

	const DuplicateUserModal = (
		<>
			{(data && data.userEmail && data.userEmail.value) &&
				<MessageModal title="Cannot create Reseller" onClick={onCloseErrorModal} size="m">
					The contact '{data.userEmail.value}' cannot be added to the Admin Portal.<br /><br />Please contact iTEL to add this Reseller and it's Primary Contact.
				</MessageModal>
			}
		</>
	);

	const AddResellerModal = (
		<MultiStepModal
			title="Add New Reseller"
			showModal={showModal}
			steps={contents.length}
			step={step}
			content={content}
			size="m"
			modalButtons={modalButtons}
			back={back}
			close={toggle}
		/>
	);

	const render = () => {
		if (reason !== LogoutReason.None) {
			return <AuthLogoutMessage reason={reason} />;
		}
		if (executingFetch || loading) {
			return <LoadingSpinner active={executingFetch || loading} />;
		}
		if (error) {
			return FetchErrorModal;
		}
		if (existingReseller) {
			return DuplicateUserModal;
		}
		return AddResellerModal;
	}

	return (
		<>{render()}</>
	);
};

export default AddReseller;