import "primereact/resources/primereact.min.css";
import "./itel_primereact_theme.css";
import "./common.css"; // Override some of the invasive bootstrap styling
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import App from "./App";
import * as serviceWorkerRegistration from "./setup/serviceWorkerRegistration";

const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href");
const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

/* 
 * <StrictMode> was removed due to an issue with reactstrap: https://github.com/react-bootstrap/react-bootstrap/issues/5075
 * Now reactstrap is removed, maybe this can be retried...
 */
root.render(
  <BrowserRouter basename={baseUrl}>
    <App />
  </BrowserRouter>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();
