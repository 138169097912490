import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleArrowUp } from "@fortawesome/free-solid-svg-icons";
import ResellerBilling from "./ResellerBilling";
import { formatAsCurrency } from "../../../utils/formatting";

const PackageBilling = ({ packages }) => {
	const [activeAccordion, setActiveAccordion] = useState(null);
	const toggleAccordion = (index) => {
		setActiveAccordion(activeAccordion === index ? null : index);
	};

	return (
		<>
			<div className="billing-table-header">
				<p className="font_m_bold">Product</p>
				<p className="font_m_bold">Customers</p>
				<p className="font_m_bold">Pricing</p>
				<p className="font_m_bold">Quantity</p>
				<p className="font_m_bold">Cost</p>
			</div>
			{packages.map((item, index) => (
				<div className={`billing-package ${activeAccordion === index ? "active" : ""}`} key={item.Name}>
					<div className="billing-package-grid" onClick={() => toggleAccordion(index)}>
						<h3>
							<span className="accordion-arrow">
								<FontAwesomeIcon icon={faCircleArrowUp} />
							</span>
							{item.Name}
						</h3>
						<p>{item.CustomerCount}</p>
						<p>{formatAsCurrency(item.Pricing)}</p>
						<p>{item.Quantity}</p>
						<p>{formatAsCurrency(item.Cost)}</p>
					</div>
					{activeAccordion === index && (
						<div className="accordion-panel">
							<ResellerBilling resellers={item.Resellers} pup={item.PUP} />
						</div>
					)}
				</div>
			))}
		</>
	);
};

export default PackageBilling;