import { useState } from "react";
import { faArrowRight, faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { isTextValid, isNameValid, isEmailValid } from "../../utils/validation";
import { useSession, LogoutReason } from "../Auth/hooks/useSession";
import AuthLogoutMessage from "../Auth/components/AuthLogoutMessage";
import { getFetchOptions } from "../../utils/fetch";
import useFetch from '../../hooks/useFetch';
import useFetchAuto from "../../hooks/useFetchAuto";
import useMultiStepModal from "../../components/modals/hooks/useMultiStepModal";
import MultiStepModal from "../../components/modals/MultiStepModal";
import LoadingSpinner from "../../components/loadingSpinner/LoadingSpinner";
import CustomerDetails from "./components/CustomerDetails";
import CustomerPackages from "./components/CustomerPackages";
import CustomerSummary from "./components/CustomerSummary";
import MessageModal from "../../components/modals/MessageModal";
import Button, { ButtonType } from "../../components/Button";

const AddCustomer = ({ showModal, setModal, setFetchParentData }) => {
	const INITIAL_DATA = {
		businessName: { value: "", validation: 0 },
		businessAddress: { value: "", validation: 0 },
		userFirstName: { value: "", validation: 0 },
		userLastName: { value: "", validation: 0 },
		userEmail: { value: "", validation: 0 },
		packageItem: {} // package is a reserved word
	};

	const { reason } = useSession();
	const toggle = () => setModal(!showModal);
	const [data, setData] = useState(INITIAL_DATA);
	const [canSubmit, setCanSubmit] = useState(false);
	const { loading, data: packageData } = useFetchAuto("/api/ListAssignablePackages/");
	const { error, execute } = useFetch();
	const [isSubmitting, setIsSubmitting] = useState(false);

	const { contents, content, step, back, next, last } = useMultiStepModal([
		<CustomerDetails {...data} onChangeInput={onChangeInput} setCanSubmit={setCanSubmit} title={"Customer Details"} />,
		<CustomerPackages {...data} packageData={packageData} onChangeInput={onChangeInput} setCanSubmit={setCanSubmit} title={"Package Details"} />,
		<CustomerSummary {...data} packageData={packageData} setCanSubmit={setCanSubmit} title={"Summary"} />
	]);

	function onChangeInput(fields) {
		setData((prev) => {
			return { ...prev, ...fields }
		});
	}

	function onClickNext() {
		if (step === 0) {
			const validations = [
				isTextValid(data.businessName.value) ? 2 : 1,
				isTextValid(data.businessAddress.value) ? 2 : 1,
				isNameValid(data.userFirstName.value) ? 2 : 1,
				isNameValid(data.userLastName.value) ? 2 : 1,
				isEmailValid(data.userEmail.value) ? 2 : 1
			];
			setData((prev) => {
				return {
					...prev,
					businessName: { value: prev.businessName.value, validation: validations[0] },
					businessAddress: { value: prev.businessAddress.value, validation: validations[1] },
					userFirstName: { value: prev.userFirstName.value, validation: validations[2] },
					userLastName: { value: prev.userLastName.value, validation: validations[3] },
					userEmail: { value: prev.userEmail.value, validation: validations[4] }
				};
			});
			const valid = validations.every((value) => value === 2);
			if (valid) {
				next();
			}
		}
		else {
			next();
		}
	}

	function onClickSubmit(enable) {
		setFetchParentData(false);
		const packageLink = packageData.find((i) => { return i.ParentId === data.packageItem.Id; });
		const options = getFetchOptions({
			method: "POST",
			body: {
				Name: data.businessName.value,
				Address: data.businessAddress.value,
				ContactFirstName: data.userFirstName.value,
				ContactLastName: data.userLastName.value,
				ContactEmail: data.userEmail.value,
				Package: {
					...data.packageItem,
					Name: packageLink.DisplayName,
					SeatBlocks: data.packageItem.Seats,
					SeatBlockSize: (!packageLink.PUP) ? packageLink.Seats : 1
				}
			}
		});
		execute("/api/Customer", options, (err) => {
			if (!err) {
				toggle();
				setFetchParentData(true);
			}
			enable();
		});
	}

	const modalButtons = (
		<>
			{(!last) ? (
				<Button type={ButtonType.Primary} disabled={!canSubmit || isSubmitting} icon={faArrowRight} iconRight label={"Next"} onClick={onClickNext} />
			) : (
					<Button
						type={ButtonType.Primary}
						disabled={!canSubmit || isSubmitting}
						icon={faCircleCheck}
						label={"Confirm and Create"}
						onClick={(e, c) => onClickSubmit(c)}
						disableUntilClickCompleted={true}
					/>
			)}
		</>
	);

	function onCloseErrorModal() {
		window.location.reload();
	}

	const ErrorModal = (
		<MessageModal title="Cannot create Customer" onClick={onCloseErrorModal} size="m">
			<p ref={(element) => { element.innerText = error.message; }} />
		</MessageModal>
	);

	const AddModal = (
		<MultiStepModal
			title="Add New Customer"
			showModal={showModal}
			steps={contents.length}
			step={step}
			content={content}
			size="m"
			modalButtons={modalButtons}
			back={back}
			close={toggle}
		/>
	);

	const render = () => {
		if (reason !== LogoutReason.None) {
			return <AuthLogoutMessage reason={reason} />;
		}
		if (loading) {
			return <LoadingSpinner active={loading} />;
		}
		if (error) {
			return ErrorModal;
		}
		return AddModal;
	}

	return (
		<>{render()}</>
	);
};

export default AddCustomer;