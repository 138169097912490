import { useRef, useEffect, useState } from "react";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { isNameValid, isTextValid } from "../../utils/validation.js";
import { getFetchOptions } from "../../utils/fetch.js";
import { updateTimestamp } from "../../utils/getUserInformation";
import MultiStepModal from "../../components/modals/MultiStepModal.js";
import Details from "./components/Details";
import Packages from "./components/Packages";
import Button, { ButtonType } from "../../components/Button";
import useMultiStepModal from "../../components/modals/hooks/useMultiStepModal";
import useFetchAuto from "../../hooks/useFetchAuto";
import useFetch from '../../hooks/useFetch';
import LoadingSpinner from "../../components/loadingSpinner/LoadingSpinner.js";

const GetStartedNew = (props) => {
	const INITIAL_DATA = useRef({
		businessName: { value: "", validation: 0 },
		businessAddress: { value: "", validation: 0 },
		userFirstName: { value: "", validation: 0 },
		userLastName: { value: "", validation: 0 },
		userEmail: "",
		packages: []
	});

	const toggle = () => props.setModal(!props.showModal);
	const [data, setData] = useState(INITIAL_DATA.current);
	const [canSubmit, setCanSubmit] = useState(false);
	const { loading, data: serverData } = useFetchAuto("/api/GetStarted/");
	const { error, execute } = useFetch();

	useEffect(() => {
		if ((!loading) && (serverData)) {
			setData((prev) => {
				return {
					...prev,
					businessName: { value: serverData.Name, validation: 2 },
					businessAddress: { value: serverData.Address, validation: 2 },
					userFirstName: { value: serverData.ContactFirstName, validation: 2 },
					userLastName: { value: serverData.ContactLastName, validation: 2 },
					userEmail: serverData.ContactEmail,
					packages: serverData.Packages
				};
			});
		}
	}, [loading, serverData]);

	const { contents, content, step, back, next } = useMultiStepModal([
		<Details {...data} onChangeInput={onChangeInput} setCanSubmit={setCanSubmit} title={"Confirm your details to get started"} />,
		<Packages {...data} title={"View Packages"} />
	]);

	function onChangeInput(fields) {
		setData((prev) => {
			return { ...prev, ...fields };
		});
	}

	function validateDetails() {
		const validations = [
			isTextValid(data.businessName.value) ? 2 : 1,
			isTextValid(data.businessAddress.value) ? 2 : 1,
			isNameValid(data.userFirstName.value) ? 2 : 1,
			isNameValid(data.userLastName.value) ? 2 : 1
		];
		setData((prev) => {
			return {
				...prev,
				businessName: { value: prev.businessName.value, validation: validations[0] },
				businessAddress: { value: prev.businessAddress.value, validation: validations[1] },
				userFirstName: { value: prev.userFirstName.value, validation: validations[2] },
				userLastName: { value: prev.userLastName.value, validation: validations[3] },
			};
		});
		return validations.every((value) => value === 2);
	}

	function updateLocalStorageDetails() {
		let item = JSON.parse(localStorage.getItem("ap-liu"));
		item = {
			...item,
			FirstName: data.userFirstName.value,
			LastName: data.userLastName.value,
			ResellerName: data.businessName.value,
			LoginType: 0
		};
		localStorage.setItem("ap-liu", JSON.stringify(item));
	}

	function updateResellerDetails(enable) {
		updateTimestamp(Math.floor(new Date().getTime() * 0.001));
		const options = getFetchOptions({
			method: "POST",
			body: {
				Id: serverData.Id,
				Name: data.businessName.value,
				Address: data.businessAddress.value,
				ContactFirstName: data.userFirstName.value,
				ContactLastName: data.userLastName.value,
				ContactEmail: data.userEmail
			}
		});
		execute("/api/GetStarted/Reseller", options, (error) => {
			if (error) {
				console.error(error.message);
			}
			else {
				updateLocalStorageDetails();
				toggle();
				props.reload(true);
				window.dispatchEvent(new Event("ap-liu-update-nav"));
			}
			enable();
		});
	}

	function onClickNext(enable) {
		if (step === 0) {
			enable();
			if (validateDetails()) {
				next();
			}
		}
		else {
			updateResellerDetails(enable);
		}
	}
	
	const modalButtons = (
		<Button
			type={ButtonType.Primary}
			disabled={!canSubmit}
			icon={faArrowRight}
			iconRight
			label={step === 0 ? "Packages" : "Home"}
			onClick={(e, c) => onClickNext(c)}
			disableUntilClickCompleted={true}
		/>
	);

	return (
		<div>
			{loading
				? <LoadingSpinner active={loading} />
				: <MultiStepModal
						title="Welcome"
						showModal={props.showModal}
						steps={contents.length}
						step={step}
						content={content}
						size="m"
						modalButtons={modalButtons}
						hideCancel={true}
						back={back}
						close={toggle}
					/>
			}
		</div>
	);
};

export default GetStartedNew;