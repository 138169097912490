import { DataTable as PrTable } from "primereact/datatable";
import { Column } from "primereact/column";

/**
 * Properties Object definition used in the DataTable component.
 * @typedef {Object} DataTableProps
 * @property {Array.<Object>} columns
 * @property {Array.<Object>} rows
 * @property {string} columnIdName
 * @property {Function} onRowClick
 * @property {number} [rowsPerPage=10] - Number of rows per page (optional, default is 10)
 */

/**
 * @component
 * @param {DataTableProps} props
 */
const DataTable = (props) => {
	if ((props.rows) && (props.rows.length)) {
		const { rowsPerPage = 10 } = props;
		const rowsPerPageOptions = [rowsPerPage, 25, 100].sort((a, b) => a - b);

		const tableProps = {
			value: props.rows,
			paginator: props.rows.length > rowsPerPage,
			removableSort: true,
			rows: rowsPerPage,
			rowsPerPageOptions: rowsPerPageOptions,
			paginatorTemplate: "CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink",
			currentPageReportTemplate: "{first} - {last} of {totalRecords}",
			rowClassName: props.rowClassName
		};

		if (props.onRowClick) {
			tableProps.onRowClick = props.onRowClick;
			tableProps.selectionMode = "single";
		}

		return (
			<PrTable {...tableProps}>
				{props.columns.map((col, i) => (
					<Column
						key={col.field || i}
						field={col.field}
						sortable={col.sortable !== undefined ? col.sortable : true}
						header={col.headerName}
						body={col.valueGetter}
						headerClassName={col.class}
						style={col.style}
					/>
				))}
			</PrTable>
		);
	}
	return null;
};

export default DataTable;
