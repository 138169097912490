import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { formatAsCurrency } from "../../../utils/formatting";

const CostTooltip = (quantity, price) => {

	const calculatedCost = quantity * price;

	return (
		<span className="tooltip">
			<span className="tooltip-icon"><FontAwesomeIcon icon={faCircleInfo} />
				<span className="tooltip-text">{quantity} x {formatAsCurrency(price)} = {formatAsCurrency(calculatedCost)}</span>
			</span>

		</span>
	);
};

export default CostTooltip;