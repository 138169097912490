import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlus, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { Dropdown } from "primereact/dropdown";

import { identicalPackages } from "../../../utils/resellerPackageUtils";
import Row from "../../../components/Row";
import Button, { ButtonType } from "../../../components/Button";

const ResellerPackages = ({ packages, initialData, packageData, onChangeInput, setCanSubmit }) => {
	const [addingPackage, setAddingPackage] = useState(false);
	const [showAdditional, setShowAdditional] = useState(true);
	const [data, setData] = useState({});
	const unselected = packageData.filter((c) => { return packages.map((i) => { return i.ParentId }).indexOf(c.ParentId) === -1 });

	function onClickAdd() {
		setData({});
		setCanSubmit(false);
		setShowAdditional(false);
		setAddingPackage(true);
	}

	function onClickDelete(index) {
		let records = [...packages];
		records.splice(index, 1);
		onChangeInput({ packages: records });
		if (!records.length) {
			onClickAdd();
		}
		else {
			setCanSubmit(!identicalPackages(initialData, records));
		}
	}

	function onSelect(data) {
		let records = [...packages];
		const newRecord = {
			Name: data.PackageName,
			CanDelete: true,
			Id: -1,
			PackageId: data.PackageId,
			ParentId: data.ParentId,
			PUP: data.PUP
		}
		records.push(newRecord);
		onChangeInput({ packages: records });
		setShowAdditional(true);
		setAddingPackage(false);
		setCanSubmit(true);
	}

	const PackageButtons = (
		<>
			{showAdditional
				&& (unselected.length > 0 && <Button type={ButtonType.Secondary} icon={faCirclePlus} label={"Assign Additional Package"} onClick={onClickAdd} />)
			}
		</>
	);

	return (
		<div>
			<p>These are the packages that the Reseller will have access to when provisioning new customers or passing on access to their retailers.</p>
			<>
				{packages.map((record, index) =>
					<>
						<div className="package-row-small grey-row">
							<p>{record.Name}</p>
							{(record.CanDelete && onClickDelete) &&
								<button className="icon-btn itelBlue" onClick={() => onClickDelete(index)} type="button">
									<FontAwesomeIcon icon={faTrashCan} />
								</button>
							}
						</div>
					</>
				)}
			</>
			<Row class="mb-4 w-6">
				{addingPackage && <Dropdown
					id="name"
					value={data}
					onChange={(e) => {
						onSelect(e.value);
					}}
					dataKey="PackageId"
					options={unselected}
					optionLabel="PackageName"
				/>}
			</Row>
			<Row class="mb-4">
				{PackageButtons}
			</Row>
		</div>
	);
};

export default ResellerPackages;