/*
export const ModalState = {
	Add: 0,
	Edit: 1,
  List: 2
};
*/

export function getPackageLevelJsx(rules, additionalOffers, itemId, itemLevel) {
	if (itemLevel === -1 || additionalOffers) {
		return <></>;
	}
  const rule = rules.find(x => x.Id === itemId);
  if (rule) {
    const level = rule.Levels.find(x => x.Id === itemLevel);
    if (level) {
			return <> (<span style={{ textTransform: 'capitalize' }}>{level.Value}</span>)</>;
    }
  }
  return <></>;
}

export function getAdditionalOffersText(offers) {
	if (offers.length) {
		return " (" + offers.map((curr) => curr.Value).join(", ") + ")";
	}
	return "";
}