export const TYPES = ["PUP", "PCP"];

export function getPackageDisplayName(packageData, id) {
	let found = packageData.find((i) => { return i.ParentId === id; });
	if (found) {
		return found.DisplayName;
	}
	console.error("getPackageDisplayName : Unexpected error finding package!");
	return "?";
}

export function getPackageId(packageData, id) {
	let found = packageData.find((i) => { return i.ParentId === id; });
	if (found) {
		return found.PackageId;
	}
	console.error("getPackageId : Unexpected error finding package!");
	return -1;
}

export function buildPackageName(parent, name) {
	return parent.length ? parent + " - " + name : name;
}

/**
 * Compares each object in an array.
 * Cannot use JSON.stringify for object comparision, in case the order changes.
 * @returns {boolean} TRUE if the two arrays are identical, FALSE otherwise.
 */
export function identicalPackages(a, b, exclude) {
	if ((!exclude) || (exclude.length === 0)) {
		const objectsEqual = (o1, o2) => (Object.keys(o1).length === Object.keys(o2).length) && (Object.keys(o1).every(p => o1[p] === o2[p]));
		return (a.length === b.length) && (a.every((o, idx) => objectsEqual(o, b[idx])));
	}
	const objectsEqual = (o1, o2) => {
		let newo1 = { ...o1 };
		let newo2 = { ...o2 };
		for (let item of exclude) {
			delete newo1[item];
			delete newo2[item];
		}
		return (Object.keys(newo1).length === Object.keys(newo2).length) && (Object.keys(newo1).every(p => newo1[p] === newo2[p]));
	};
	return (a.length === b.length) && (a.every((o, idx) => objectsEqual(o, b[idx])));
}

export const SummaryText =
	"You are adding a new Reseller with the following details. Please make sure the details are correct and that the Reseller you named is expecting an email " +
	"from ucSKILLS to get set up in the Admin Portal.";