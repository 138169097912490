import { useState } from "react";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { useSession, LogoutReason } from "../Auth/hooks/useSession";
import AuthLogoutMessage from "../Auth/components/AuthLogoutMessage";
import { getFetchOptions } from "../../utils/fetch";
import { getPackageId } from "../../utils/resellerPackageUtils";
import useFetch from "../../hooks/useFetch";
import useFetchAuto from "../../hooks/useFetchAuto";
import ExpiryMessage from "../Auth/components/ExpiryMessage";
import LoadingSpinner from "../../components/loadingSpinner/LoadingSpinner";
import Modal from "../../components/modals/Modal";
import MessageModal from "../../components/modals/MessageModal";
import BillableResellerPackages from "./components/BillableResellerPackages";
import ResellerPackages from "./components/ResellerPackages";
import Button, { ButtonType } from "../../components/Button";
import Alert, { AlertType } from "../../components/Alert";

const EditReseller = (props) => {
	const initialData = (props.master)
		? props.items.map(x => {
			return {
				id: x.Id,
				name: x.ParentId,
				type: x.PUP ? 0 : 1,
				seats: x.Seats,
				bronze: x.BronzePrice,
				silver: x.SilverPrice,
				gold: x.GoldPrice,
				canDelete: ("CanDelete" in x) ? x.CanDelete : true
			}
		})
		: props.items;
	const { reason } = useSession();
	const [inputValues, setInputValues] = useState({ packages: initialData });
	const [canSubmit, setCanSubmit] = useState(false);
	const { loading, data: packageData } = useFetchAuto("/api/ListAssignablePackages/");
	const { execute, executingFetch, error } = useFetch();
	
	function onChangeInput(fields) {
		setInputValues((prev) => {
			return { ...prev, ...fields }
		});
	}

	function onClickCancel() {
		props.setOpenEditor(false);
	}
	
	function onClickConfirm(enable) {
		const packages = (props.master) ?
			inputValues.packages.map((curr) => {
				return {
					Id: curr.id || -1,
					ParentId: curr.name,
					PackageId: getPackageId(packageData, curr.name),
					PUP: curr.type === 0,
					Seats: Number(curr.seats),
					BronzePrice: Number(curr.bronze),
					SilverPrice: Number(curr.silver),
					GoldPrice: Number(curr.gold)
				};
			}) :
			inputValues.packages.map((curr) => {
				return {
					Id: curr.Id || -1,
					ParentId: curr.ParentId,
					PackageId: curr.PackageId
				};
			});
		const options = getFetchOptions({
			method: "POST",
			body: packages
		});
		execute("/api/Reseller/Packages/" + props.resellerId, options, (error) => {
			if (error) {
				console.error(error.message);
			}
			else {
				props.setOpenEditor(false);
			}
			enable();
		});
	}

	function onCloseErrorModal() {
		window.location.reload();
	}

	const content = (
		<div>
			{(props.master) ?
				(
					<BillableResellerPackages
						{...inputValues}
						initialData={initialData}
						packageData={packageData}
						onChangeInput={onChangeInput}
						setCanSubmit={setCanSubmit}
						editing={true}
						title={"Assign Packages"}
					/>
				) : (
					<ResellerPackages
						{...inputValues}
						initialData={initialData}
						packageData={packageData}
						onChangeInput={onChangeInput}
						setCanSubmit={setCanSubmit}
						editing={true}
						title={"Assign Packages"}
					/>
				)
			}
		</div>
	);

	const buttons = (
		<>
			<Button type={ButtonType.Tertiary} label={"Cancel"} onClick={onClickCancel} />
			<Button
				type={ButtonType.Primary}
				disabled={!canSubmit}
				icon={faCircleCheck}
				label={"Confirm"}
				onClick={(e, c) => onClickConfirm(c)}
				disableUntilClickCompleted={true}
			/>
		</>
	);

	const ErrorModal = (
		<>
			{error &&
				<MessageModal title="Cannot edit Reseller" onClick={onCloseErrorModal} size="m">
					<Alert type={AlertType.Error}>{error.message}</Alert>
				</MessageModal>
			}
		</>
	);

	const EditModal = (
		<Modal
			title="Edit Reseller Packages"
			showModal={props.showModal}
			content={content}
			size="m"
			modalButtons={buttons}
			close={onClickCancel}
		/>
	);

	const render = () => {
		if (reason !== LogoutReason.None) {
			return <AuthLogoutMessage reason={reason} />;
		}
		if (executingFetch || loading) {
			return <LoadingSpinner active={executingFetch || loading} />;
		}
		if (props.open && !loading) {
			if (error) {
				return ErrorModal;
			}
			return EditModal;
		}
		return <></>;
	}

	return (
		<>{render()}</>
	);
};

export default EditReseller;