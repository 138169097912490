import { useEffect, useRef, useState } from "react";

/**
 * This hook checks whether cached data exists and fetches an array of configuration variables held on the server if not.
 * The configuration variables are originally set in the server's appsettings.json file by the Release (deployment) Pipeline.
 * @returns {Object} An object containing configuration variables required for MSAL.
 */
const useConfig = () => {
	const cache = useRef({});
	const [config, setConfig] = useState({});

	useEffect(() => {
		const loadAsync = async () => {
			if (cache.current.length) {
				setConfig(cache.current);
				return;
			}

			const response = await fetch("api/Config");
			const data = await response.json();
			cache.current = data;
			setConfig(data);
		};
		loadAsync();
	}, []);

	return [config];
};

export default useConfig;