import { faCircleCheck, faCirclePlus } from "@fortawesome/free-solid-svg-icons";
import { useRef, useState } from "react";
import Alert, { AlertType } from "../../../components/Alert";
import Button, { ButtonType } from "../../../components/Button";
import PackageHeaderColumns from "../../../components/PackageHeaderColumns";
import Row from "../../../components/Row";
import { getPackageDisplayName, identicalPackages, TYPES } from "../../../utils/resellerPackageUtils";
import "./../resellers.scss";
import BillableResellerPackageRow from "./BillableResellerPackageRow";
import PackageDataRow from "./PackageDataRow";

const BillableResellerPackages = ({ packages, initialData, packageData, onChangeInput, setCanSubmit, editing, title }) => {
	const [addingPackage, setAddingPackage] = useState(!editing);
	const [showAdditional, setShowAdditional] = useState(editing);
	const [canConfirm, setCanConfirm] = useState(false);

	const INITIAL_DATA_RECORD = useRef({
		name: -1,
		type: -1,
		seats: null,
		bronze: null,
		silver: null,
		gold: null
	});
	const [data, setData] = useState(INITIAL_DATA_RECORD.current);

	function handleChangePackageInput(fields) {
		setData((prev) => {
			return { ...prev, ...fields }
		});
	}

	function handleClickAdd() {
		setData(INITIAL_DATA_RECORD.current);
		setCanSubmit(false);
		setShowAdditional(false);
		setAddingPackage(true);
	}

	function handleClickEdit(index) {
		let editData = { ...packages[index] };
		handleClickDelete(index);
		setData(editData);
		setCanSubmit(false);
		setShowAdditional(false);
		setAddingPackage(true);
	}

	function handleClickCancel() {
		setShowAdditional(true);
		setAddingPackage(false);
	}

	function handleClickDelete(index) {
		let records = [...packages];
		records.splice(index, 1);
		onChangeInput({ packages: records });
		if (!records.length) {
			handleClickAdd();
		}
		else {
			setCanSubmit(!identicalPackages(initialData, records, ["id", "canDelete"]));
		}
	}

	function handleClickConfirm() {
		let records = [...packages];
		records.push(data);
		onChangeInput({ packages: records });
		setShowAdditional(true);
		setAddingPackage(false);
		if (editing) {
			setCanSubmit(!identicalPackages(initialData, records, ["id", "canDelete"]));
		}
		else {
			setCanSubmit(true);
		}
	}

	function canDelete(record) {
		const result = (!addingPackage || editing);
		if ((result) && ("canDelete" in record)) {
			return record.canDelete;
		}
		return result;
	}

	return (
		<div>
			{(!title.startsWith("Edit")) &&
				<>
					<p>These are the packages that the Reseller will have access to when provisioning new customers or passing on access to their retailers.</p>
					<Alert type={AlertType.Warning}>It is crucial to input the correct values below, as they directly determine the billing of the Reseller.</Alert>
				</>
			}
			{(packages.length > 0) &&
				<>
					<PackageHeaderColumns editable={true} showSeatsAndPrices={true} />
					{packages.map((record, index) =>
						<PackageDataRow
							key={index}
							index={index}
							packageName={getPackageDisplayName(packageData, record.name)}
							pup={record.type}
							seats={record.seats}
							bronze={record.bronze}
							silver={record.silver}
							gold={record.gold}
							hasEdit={editing && !addingPackage}
							onClickEdit={handleClickEdit}
							hasDelete={true}
							onClickDelete={(canDelete(record)) ? handleClickDelete : null}
						/>
					)}
				</>
			}
			{addingPackage && <>
				<h4 className="mt-4">Add or Edit Package</h4>
				<PackageHeaderColumns editable={false} showSeatsAndPrices={true} />
				<BillableResellerPackageRow
					{...data}
					names={packageData}
					types={TYPES.map((curr, index) => { return { id: index, value: curr } })}
					hasEdit={editing}
					onChangePackageInput={handleChangePackageInput}
					setCanConfirm={setCanConfirm}
				/>
			</>}
			
			{showAdditional
				? <Row class="mb-4"><Button type={ButtonType.Secondary} icon={faCirclePlus} label={"Add Additional Package"} onClick={handleClickAdd} /></Row>
				: <Row class="mb-4 d-flex justify-content-end">
						<Button type={ButtonType.Tertiary} label={"Cancel"} onClick={handleClickCancel} />
						<Button type={ButtonType.Primary} disabled={!canConfirm} icon={faCircleCheck} label={"Confirm"} onClick={handleClickConfirm} />
					</Row>
				}
		</div>
	);
};

export default BillableResellerPackages;