import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication, EventType } from "@azure/msal-browser";

import { getMsalConfig } from "../configuration.js";
import { loginSuccessEventCallback } from "../loginSuccess.js";
import { forgotPasswordEventCallback } from "../forgotPassword.js";
import BetweenPages from "./BetweenPages";
import useConfig from "../hooks/useConfig";

/**
 * Sets up the object used to perform authentication and authorization functions and handles active account assignment.
 * @returns {IPublicClientApplication} Client Application Interface
 */
function setupMsal() {
	const msalInstance = new PublicClientApplication(getMsalConfig());
	const allAccounts = msalInstance.getAllAccounts();
	if (!msalInstance.getActiveAccount() && allAccounts.length > 0) {
		msalInstance.setActiveAccount(allAccounts[0]);
	}

	msalInstance.addEventCallback((event) => {
		const events = (
			event.eventType === EventType.LOGIN_SUCCESS ||
			event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
			event.eventType === EventType.SSO_SILENT_SUCCESS
		);
		if (events) {
			if (event.payload.account) {
				msalInstance.setActiveAccount(event.payload.account);
			}
			else {
				const allAccounts = msalInstance.getAllAccounts();
				if (allAccounts.length > 0) {
					msalInstance.setActiveAccount(allAccounts[0]);
				}
			}
		}

		if (event.eventType === EventType.LOGIN_SUCCESS) {
			const performLogin = async () => {
				await loginSuccessEventCallback(msalInstance);
			};
			performLogin();
		}

		if (event.eventType === EventType.LOGIN_FAILURE) {
			forgotPasswordEventCallback(event, msalInstance);
		}
	});

	return msalInstance;
}

/**
 * This component utilises the useConfig hook, creates the PublicClientApplication instance, and wraps the MsalProvider component.
 * The PublicClientApplication instance is added as a prop to Azure's MsalProvider context provider component, which must be rendered above 
 * any other components that use MSAL.
 * @component
 */
const DynamicConfigMsalProvider = (props) => {
	const [config] = useConfig();
	if (!config?.length) {
		return <BetweenPages />;
	}

	window.env = {
		clientId: config[0],
		instance: config[1],
		domain: config[2],
		appIdUri: config[3]
	};
	const msalInstance = setupMsal();
	return <MsalProvider instance={msalInstance}>{props.children}</MsalProvider>;
};

export default DynamicConfigMsalProvider;