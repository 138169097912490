import { useEffect, useState } from "react";
import { faPencil, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { useSession, LogoutReason } from "../Auth/hooks/useSession";
import AuthLogoutMessage from "../Auth/components/AuthLogoutMessage";
import { getFetchOptions } from "../../utils/fetch";
import { formatDate } from "../../utils/formatting";
import useFetch from '../../hooks/useFetch';
import MessageModal, { MessageModalType } from "../../components/modals/MessageModal";
import Modal from "../../components/modals/Modal";
import Button, { ButtonType } from "../../components/Button";
import LoadingSpinner from "../../components/loadingSpinner/LoadingSpinner";
import "./members.css";

const ViewMember = (props) => {
	const { reason } = useSession();
	const item = props.item;
	const [deleting, setDeleting] = useState(false);
	const [confirmDelete, setConfirmDelete] = useState(false);
	const [confirmResend, setConfirmResend] = useState(false);
	const { ready, execute, executingFetch } = useFetch();

	// Perform REST request.
	useEffect(() => {
		if (ready && deleting) {
			props.setFetchParentData(false);
			const options = getFetchOptions({
				method: "DELETE"
			});
			execute("/api/Member/" + props.item.Id, options, (error) => {
				setDeleting(false);
				if (error) {
					console.error(error.message);
				}
				props.setFetchParentData(true);
				props.setModal(false);
			});
		}
	}, [ready, props.item, deleting, execute]);

	function onClickClose(modal) {
		props.setFetchParentData(true);
		props.setModal(modal);
	}

	function onClickEdit() {
		props.setFetchParentData(false);
		props.setOpenEditor(true);
	}

	function onClickSendEmail() {
		setConfirmResend(true);
	}

	function onClickConfirmResend(yes) {
		if (yes) {
			const options = getFetchOptions({
				method: "POST",
				body: {
					B2CId: item.B2CId,
					FirstName: item.FirstName,
					LastName: item.LastName,
					Email: item.Email,
					Registration: item.Registration
				}
			});
			execute("/api/ResendCompleteRegistrationEmail", options, (error) => {
				if (error) {
					console.error(error.message);
				}
				else {
					props.setModal(false);
				}
			});
		}
		setConfirmResend(false);
	}

	function onClickDelete() {
		setConfirmDelete(true);
	}

	function onClickConfirmDelete(yes) {
		if (yes) {
			setDeleting(true);
		}
		setConfirmDelete(false);
	}

	const ViewContent = (
		<div>
			<h2>Team Member Details</h2>
			{item &&
				<>
					<div className="grid ">
						<div className="col-3">
							<p>Team Member Name</p>
						</div>
						<div className="col-9">
							<p className="tmd-edit-value-text">{item.FirstName}&nbsp;{item.LastName}</p>
						</div>
					</div>
					<div className="grid ">
						<div className="col-3">
							<p>Team Member Email</p>
						</div>
						<div className="col-9">
							<p className="tmd-edit-value-text">{item.Email}</p>
						</div>
					</div>
					<div className="grid ">
						<div className="col-3">
							<p>Date Created</p>
						</div>
						<div className="col-9">
							<p className="tmd-edit-value-text">{formatDate(item.CreationDate)}</p>
						</div>
					</div>
					<div className="grid ">
						<div className="col-3">
							<p>Last Login Date</p>
						</div>
						<div className="col-9">
							<p className="tmd-edit-value-text">{item.Registration ? <em className="grey">Not Registered</em> : formatDate(item.LastLoginDate)}</p>
						</div>
				</div>
				<div className="grid">
					<div className="col-3">
						<p>Registration Email Sent Date</p>
					</div>
					<div className="col-9">
						<p className="tmd-edit-value-text">{formatDate(item.LastRegistrationEmailDate)}</p>
					</div>
				</div>
				</>
			}
		</div>
	);

	const buttons = (
		<>
			<Button type={ButtonType.Secondary} icon={faPencil} label={"Edit Details"} onClick={onClickEdit} />
			{!item.LastLoginDate && <Button type={ButtonType.Secondary} icon={faEnvelope} label={"Resend Welcome Email"} onClick={onClickSendEmail} />}
			{!item.Self && <Button type={ButtonType.Tertiary} label={"Delete"} onClick={onClickDelete} />}
		</>
	);

	const DeleteModal = (
		<MessageModal title="Confirm Member Deletion" type={MessageModalType.Question} onClick={onClickConfirmDelete} size="m">
			Are you sure you want to delete {item.FirstName} {item.LastName}?
		</MessageModal>
	);

	const ResendModal = (
		<MessageModal title="Confirm Resend" type={MessageModalType.Question} onClick={onClickConfirmResend} size="m">
			Are you sure you want to resend Welcome Email to {item.FirstName} {item.LastName}?
		</MessageModal>
	);

	const ViewModal = (
		<Modal
			title="Team Member Details"
			showModal={props.showModal}
			content={ViewContent}
			size="m"
			modalButtons={buttons}
			close={onClickClose}
		/>
	);

	const render = () => {
		if (reason !== LogoutReason.None) {
			return <AuthLogoutMessage reason={reason} />;
		}
		if (executingFetch) {
			return <LoadingSpinner active={executingFetch} />;
		}
		if (confirmDelete) {
			return DeleteModal;
		}
		if (confirmResend) {
			return ResendModal;
		}
		return ViewModal;
	}

	return (
		<>{render()}</>
	);
};

export default ViewMember;