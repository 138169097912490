import { useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { getReadScope } from "../configuration.js";

/**
 * This hook is used to get the signed in User's Jwt token.
 * @returns {string} A Jwt token string.
 */
const useAccessToken = () => {
	const { instance } = useMsal();
	const account = instance.getActiveAccount();
	const [accessToken, setAccessToken] = useState(null);

	useEffect(() => {
		if (account) {
			const acquire = async () => {
				const response = await instance.acquireTokenSilent({
					scopes: getReadScope(),
					account: account
				});
				setAccessToken(response.accessToken);
			};
			acquire().catch(console.error);
		}
	}, [instance, account]); // "account" dependency can cause an infinite access token loop (usually resulting in a 400 error)

	return {
		accessToken
	};
};

export default useAccessToken;