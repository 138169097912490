import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './IconCard.scss';

const IconCard = ({ children, ...props }) => {

	return (
		<>
			{props.link ?
				<Link className="iconCard iconCardWithLink" to={props.link}>
					<div className="iconCard_content">
						<div className="iconCard_content_title">
							{props.title && <h2>{props.title}</h2>}
						</div>
						{props.imgSrc &&
							<div className="iconCard_content_image">
								<img alt={"Image for " + props.title} src={props.imgSrc}></img>
							</div>
						}
						{props.icon &&
							<div className="iconCard_content_image">
								<FontAwesomeIcon icon={props.icon} />
							</div>
						}
						<div className="iconCard_content_text">
							<p>{children}</p>
						</div>

					</div>
				</Link>
				:
				<div className="iconCard">
					<div className="iconCard_content">
						{props.title && <h2>{props.title}</h2>}
						<div className="iconCard_content_text">
							<p>{children}</p>
						</div>
						<div className="iconCard_content_image">
							<img alt={"Image for " + props.title} src={props.imgSrc}></img>
						</div>
					</div>
				</div>
			}
		</>
	)
}

export default IconCard;