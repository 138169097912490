import { useEffect, useState } from "react";

const useFileHandler = (fileUploadRef) => {
	const [selectedFile, setSelectedFile] = useState(null);
	const [fileSelected, setFileSelected] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');
	const [isFileChanged, setIsFileChanged] = useState(false);

	const validateFile = (file) => {
		if (file.type !== 'application/pdf') {
			return false;
		}
		return true;
	};

	const resetFileSelection = () => {
		setFileSelected(false);
		setSelectedFile(null);
		setErrorMessage('');
	};

	useEffect(() => {
		if (isFileChanged) {
			const currentFiles = fileUploadRef.current.getFiles();
			if (currentFiles && currentFiles.length > 0) {
				const isFileValid = validateFile(currentFiles[0]);
				if (isFileValid) {
					setFileSelected(true);
					setSelectedFile(currentFiles[0]);
				}
				else {
					setFileSelected(false);
					setSelectedFile(null);
					setErrorMessage("Incorrect file type selected for upload. Please upload a PDF file.");
				}
			} else {
				setFileSelected(false);
				setSelectedFile(null);
			}
			setIsFileChanged(false);
		}
	}, [isFileChanged, fileUploadRef]);

	const triggerFileCheck = () => setIsFileChanged(true);

	return { fileSelected, selectedFile, errorMessage, triggerFileCheck, resetFileSelection };
};

export default useFileHandler;