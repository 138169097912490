import { useMsal } from "@azure/msal-react";
import { getUserEmail } from "../../../utils/getUserInformation";
import { LogoutReason } from "../hooks/useSession";
import MessageModal from "../../../components/modals/MessageModal";

const AuthLogoutMessage = ({ reason }) => {
	const { instance } = useMsal();

	function onCloseErrorModal() {
		if (reason === LogoutReason.Expired) {
			const email = btoa(getUserEmail());
			localStorage.setItem("ap-lau-" + email, window.location.pathname);
			localStorage.removeItem("ap-liu");
		}
		instance.logout();
	}

	const title = (reason === LogoutReason.Expired) ? "Session Expired" : "Logged Out";
	const message = (reason === LogoutReason.Expired)
		? "Due to 60 minutes of inactivity, you are now being logged out. You will be returned to this page when you next log in."
		: "You are being redirected to the login page as another tab is using this portal.";

	return (
		<MessageModal title={title} onClick={onCloseErrorModal} size="m">{message}</MessageModal>
	);
};

export default AuthLogoutMessage;