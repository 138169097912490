import { Route, Routes } from "react-router-dom";
import CompleteRegistration from "../features/Registration/CompleteRegistration";
import AppRoutes from "../AppRoutes";
import Layout from "./Layout";

const Pages = () => {
	return (
		<>
			<Routes>
				<Route path="/CompleteRegistration" element={<CompleteRegistration />} />
				<Route path="/CompleteRegistration/:id" element={<CompleteRegistration />} />
				<Route element={<Layout />}>
					{AppRoutes.map((route, index) => {
						const { element, ...rest } = route;
						return <Route key={index} {...rest} element={element} />;
					})}
				</Route>
			</Routes>
		</>
	);
};

export default Pages;