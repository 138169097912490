import React, { useState, useEffect } from "react";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import { getUserResellerName, getUserResellerType, ResellerType } from "./../../utils/getUserInformation";
import { formatDate } from "../../utils/formatting";
import { getFetchOptions } from "../../utils/fetch";
import useLocalStorage from "./../../hooks/useLocalStorage";
import useFetch from "../../hooks/useFetch";
import { useSession, LogoutReason } from "../Auth/hooks/useSession";
import AuthLogoutMessage from "../Auth/components/AuthLogoutMessage";
import LoadingSpinner from "../../components/loadingSpinner/LoadingSpinner";
import useFetchAuto from "../../hooks/useFetchAuto";
import LinkCard from "../../components/linkCard/LinkCard";
import UploadInvoices from "./UploadInvoices";
import SelectReseller from "./SelectReseller";
import "./Invoices.scss";

const ListInvoices = () => {
	const { reason } = useSession();
	const [userType, setUserType] = useState();
	const [selectedReseller, setSelectedReseller] = useState();
	const [data, setData] = useState(null);
	const [resellerSelected, setResellerSelected] = useState(false);
	const { loading, data: fetchData } = useFetchAuto("/api/Invoices/0", null);
	const { ready, execute, executingFetch } = useFetch();
	
	useLocalStorage("ap-liu", () => {
		setUserType(getUserResellerType());
	});

	useEffect(() => {
		if (userType !== ResellerType.iTEL) {
			setSelectedReseller(getUserResellerName());
		}
	}, [userType]);

	const handleSelectReseller = (reseller) => {
		setSelectedReseller(reseller);
		if (ready && !loading && reseller !== null) {
			const options = getFetchOptions({
				method: "GET"
			});
			execute("/api/Invoices/" + reseller.Id, options, (error, newData) => {
				if (error) {
					console.error(error.message);
				}
				else {
					setData(newData);
				}
			});
		}
		setResellerSelected(reseller !== null);
	};

	const renderData = data || fetchData;

	const renderInvoiceList = () => {
		if (executingFetch || loading) {
			return <LoadingSpinner active={executingFetch || loading} />;
		}
		if (renderData && renderData.length) {
			const sortedData = [...renderData].sort((a, b) =>
				new Date(b.InvoiceDate) - new Date(a.InvoiceDate)
			);
			const sortedInvoiceList = sortedData.map((item) => (
				<LinkCard link={item.InvoiceBlobUri} icon={faFilePdf} buttonText="View PDF">
					{formatDate(item.InvoiceDate)} - {item.Reseller.Name}
				</LinkCard>
			))
			return sortedInvoiceList;
		}
		return <p>No invoices uploaded yet</p>;
	};

	return (
		<>
			{(reason !== LogoutReason.None)
				? <AuthLogoutMessage reason={reason} />
				: (<>
					{userType === ResellerType.iTEL && (
						<div className="mb-6">
							<h1>Billing Admin</h1>
							<SelectReseller onSelectReseller={handleSelectReseller}></SelectReseller>
							{resellerSelected && (
								<>
									<h2 className="mb-4 mt-6">Upload Invoices</h2>
									<UploadInvoices selectedReseller={selectedReseller}></UploadInvoices>
								</>
							)}
						</div>
					)}
					{(userType === ResellerType.Billable || (userType === ResellerType.iTEL && selectedReseller)) && (
						<div>
							<h1>Billing {selectedReseller.Name ? <>for {selectedReseller.Name}</> : <>for {selectedReseller}</>}</h1>
							{userType === ResellerType.Billable && <p className="mb-4">
								Download your monthly invoices below. Dig into your sales figures and those of your resellers to understand your billing and performance.
							</p>}
							{userType === ResellerType.iTEL && <p className="mb-4">Below are the invoices exactly as the selected company will see them.</p>}
							<h2 className="mb-4 mt-6">Your Invoices</h2>
							{renderInvoiceList()}
						</div>
					)}
				</>)
			}
		</>
	);
};

export default ListInvoices;
