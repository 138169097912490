import DataTable from "../../../components/DataTable";
import { formatDate } from "../../../utils/formatting";
import { getAdditionalOffersText, getPackageLevelJsx } from "../utilities";

const ProvisionedTable = ({ provisioned, setShowModal, setEditing, setShowSelectedPackages, setProvisioningCustomer, setPackagesToAdd, setMarketingConsent, rules }) => {
		
	const onClickCompany = (rowData) => {
		setProvisioningCustomer({
			name: rowData.data.Name,
			accountid: rowData.data.ProvisionId
		});
		setPackagesToAdd(rowData.data.Packages.map((curr) => {
			return {
				packageId: curr.Id,
				packageName: curr.Name,
				seats: curr.SeatBlocks,
				seatBlockSize: curr.SeatBlockSize,
				packageLevel: curr.Level,
				additionalOffers: curr.AdditionalOffers
			};
		}));
		setMarketingConsent(rowData.data.MarketingConsent);
		setEditing(true);
		setShowSelectedPackages(true);
		setShowModal(true);
	};

	const getPackages = (packages) => {
		if (!packages.length) {
			return <div className="no-packages">No Packages</div>
		}
		return <>
			{packages.map((pkg, index) => (
				<div key={index}>
				{pkg.Name}
				{getPackageLevelJsx(rules, pkg.AdditionalOffers && pkg.AdditionalOffers.length, pkg.Id, pkg.Level)}
				{getAdditionalOffersText(pkg.AdditionalOffers)}
				</div>
			))}
		</>;
	};

	const getPackageSeats = (packages) => {
		if (!packages.length) {
			return <>-</>;
		}
		return <>
			{packages.map((pkg, index) => (
				<div key={index}>{pkg.SeatBlocks} x {pkg.SeatBlockSize}</div>
			))}
		</>;
	};

	const columns = [
		{
			field: "Name",
			headerName: "Company",
		},
		{
			headerName: "Packages",
			valueGetter: (rowData) => getPackages(rowData.Packages),
			sortable: false,
		},
		{
			headerName: "Seats",
			valueGetter: (rowData) => getPackageSeats(rowData.Packages),
			sortable: false,
		},
		{
			field: "CreationDate",
			headerName: "Date Created",
			valueGetter: (rowData) => formatDate(rowData.CreationDate),
			style: { width: '110px' },
		},
		{
			field: "LastLoginDate",
			headerName: "Last Accessed",
			valueGetter: (rowData) => formatDate(rowData.LastLoginDate),
			style: { width: '110px' },
		},
		{
			field: "MarketingConsent",
			headerName: "Marketing Consent",
			valueGetter: (rowData) => rowData.MarketingConsent ? "Yes" : "No",
			style: { width: '110px', textAlign: 'center' },
		}
	];

	return (
		<>
			<h2>Currently Provisioned Customers</h2>
			{provisioned.length === 0 ? (
				<div className='noResults'><p>No results</p></div>
				) : (
					<DataTable columns={columns} rows={provisioned} columnIdName="Id" className="provisioned-table" onRowClick={(params) => onClickCompany(params)} />
			)}
		</>
	);
};

export default ProvisionedTable;
