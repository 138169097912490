import { InputTextarea } from "primereact/inputtextarea";
import Row from "./Row";
import InputTextWithValidation from "./InputTextWithValidation";

const BusinessInputs = ({ nameLabel, name, addressLabel, address, onChangeInput }) => {
	return (
		<>
			<div className="form-row">
				<label className="form-row-label align-self-center" htmlFor="name">{nameLabel}</label>
				<InputTextWithValidation
					id="name"
					data={name}
					maxLength="150"
					onChange={e => onChangeInput({ businessName: { value: e.target.value, validation: 0 } })}
					feedback="The name contains invalid characters."
					placeholder={nameLabel}
					responsiveClass="col-span-2"
				/>
			</div>
			<div className="form-row">
				<label className="form-row-label pt-2" htmlFor="address">{addressLabel}</label>
					<InputTextarea
						id="address"
						className={`col-span-2 ${address.validation === 1 ? "ap-invalid" : ""}`}
						maxLength="3000"
						rows={4}
						autoResize
						onChange={e => onChangeInput({ businessAddress: { value: e.target.value, validation: 0 } })}
						value={address.value}
						placeholder={addressLabel}
					/>
					{(address.validation === 1) &&
						<>
							<div className="ap-invalid-icon">!</div>
							<div className="ap-invalid-feedback">The address contains invalid characters.</div>
						</>
					}
			</div>
		</>
	);
};

export default BusinessInputs;