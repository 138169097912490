import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { Password } from "primereact/password";
import { faCircleCheck, faExclamation } from "@fortawesome/free-solid-svg-icons";

import { getFetchOptions } from "../../utils/fetch";
import { isGuidValid } from "../../utils/validation";
import MessageModal from "../../components/modals/MessageModal";
import Button, { ButtonType } from "../../components/Button";
import Alert, { AlertType } from "../../components/Alert";
import logo_ucs from "../../assets/bob-ucskills-logo.svg";
import logo_ap from "../../assets/b-admin-portal.svg";
import "./CompleteRegistration.scss";

const CompleteRegistration = () => {
	let { id } = useParams();
	const { instance } = useMsal();
	const [password, setPassword] = useState("");
	const [passwordConfirmation, setPasswordConfirmation] = useState("");
	const [passwordValidation, setPasswordValidation] = useState({ first: true, firstError: "", second: true, secondError: "" });
	const [showModal, setShowModal] = useState(false);
	const [errorMessage, setErrorMessage] = useState("This page has been accessed illegally.");
	
	// Check if the parameter is a valid Guid; if not set to NULL, forcing the modal dialog.
	if (!isGuidValid(id)) {
		id = null;
	}

	const logout = () => {
		localStorage.removeItem("ap-liu");
		instance.logout();
	};

	useEffect(() => {
		fetch("/api/GetHasRegistered/" + id)
			.then((response) => {
				if (response.ok) {
					return response.json();
				}
			})
			.then((data) => {
				if (data) {
					logout();
				}
			})
			.catch((error) => {
				setErrorMessage(error);
				setShowModal(true);
			});
	}, []);

	function validate() {
		return true;
		let pwValid = true;
		if ((!password.trim().length) || (password.length < 8)) {
			pwValid = false;
		}
		else {
			let valid = 0;
			const expressions = ["^.*[A-Z]+.*$", "^.*[a-z]+.*$", "^.*[0-9]+.*$", "^.*[^a-zA-Z0-9]+.*$"];
			for (let expression of expressions) {
				let regex = new RegExp(expression);
				if (regex.test(password)) {
					++valid;
				}
			}
			pwValid = (valid >= 3);
		}
		const pwMatch = password === passwordConfirmation;
		setPasswordValidation({
			first: pwValid,
			firstError: pwValid ? "" : "Your password does not meet the above requirements",
			second: pwMatch,
			secondError: pwMatch ? "" : "The passwords do not match"
		});
		return (pwValid && pwMatch);
	}

	function onClickSubmit() {
		if (validate()) {
			const options = getFetchOptions({
				method: "POST",
				body: {
					Id: id,
					Value: btoa(password)
				}
			});
			fetch("/api/CompleteRegistration", options)
				.then((response) => {
					if (response.status >= 300) {
						response.text().then((o) => {
							setErrorMessage(o);
							setShowModal(true);
						});
					}
					else {
						logout();
					}
				})
				.catch((error) => {
					setErrorMessage(error);
					setShowModal(true);
				});
		}
	}

	return (
		<div className="container">
			{(id && !showModal) ? (
				<div className="col-4 col-offset-4">
					<div id="logo">
						<img className="mr3pc" alt="ucSKILLS" src={logo_ucs} width="68%" height="46" />
						<img alt="Admin Portal" src={logo_ap} width="29%" height="46" />
					</div>
					<h1 className="text-center my-4">Complete Registration</h1>
					<p className="mb-1">Your password must contain between 8 and 64 characters and contain at least 3 of the following:</p>
					<ul className="pw-rules small">
						<li>1 or more uppercase letters</li>
						<li>1 or more lowercase letters</li>
						<li>1 or more numbers</li>
						<li>1 or more symbols</li>
					</ul>
					<label className="ap-label w-full mb-2" htmlFor="sesame-value">Password</label>
					<div className="relative">
						<Password
							id="sesame-value"
							className={(!passwordValidation.first) ? "w-full ap-invalid" : "w-full"}
							value={password}
							placeholder="Password"
							maxLength="64"
							feedback={false}
							onChange={e => setPassword(e.target.value)}
						/>
						{(!passwordValidation.first) &&
							<>
								<div className="ap-invalid-icon">!</div>
								<div className="ap-invalid-feedback">{passwordValidation.firstError}</div>
							</>
						}
					</div>
					<label className="ap-label w-full mt-4 mb-2" htmlFor="sesame-value">Re-enter your Password</label>
					<div className="relative">
						<Password
							id="sesame-confirm-value"
							className={(!passwordValidation.second) ? "w-full ap-invalid" : "w-full"}
							value={passwordConfirmation}
							placeholder="Confirm Password"
							maxLength="64"
							feedback={false}
							onChange={e => setPasswordConfirmation(e.target.value)}
						/>
						{(!passwordValidation.second) &&
							<>
								<div className="ap-invalid-icon">!</div>
								<div className="ap-invalid-feedback">{passwordValidation.secondError}</div>
							</>
						}
					</div>
					<div className="buttons-r mt-4">
						<Button type={ButtonType.Primary} icon={faCircleCheck} label={"Submit"} onClick={onClickSubmit} />
					</div>
				</div>
			) : (
					<MessageModal title="Error" onClick={logout} size="m">
					<Alert type={AlertType.Error}>{errorMessage}<br />You will be redirected to the login page.</Alert>
					<p>Click Ok below to continue.</p>
				</MessageModal>
			)}
		</div>
	);
};

export default CompleteRegistration;