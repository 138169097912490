import { Dropdown } from "primereact/dropdown";

const PackageSelectionSection = ({ offerRules, showSelectedPackages, dropdownOptions, selectedPackage, handleSelectedPackage }) => {
	return (
		<>
			{offerRules.length && (
				<>
					<h4>Select Package</h4>
					<p>A customer needs at least one package to be provisioned.</p>
					<Dropdown
						value={showSelectedPackages ? null : selectedPackage}
						options={dropdownOptions}
						optionLabel="Name"
						optionValue="Id"
						onChange={(e) => handleSelectedPackage(e.value)}
						placeholder="Select a Package"
					/>
				</>
			)}
		</>
	);
};

export default PackageSelectionSection;