import { useEffect } from "react";
import { InputNumber } from "primereact/inputnumber";
import { Dropdown } from "primereact/dropdown";

const BillableResellerPackageRow = ({ names, name, types, type, seats, bronze, silver, gold, hasEdit, onChangePackageInput, setCanConfirm }) => {
	useEffect(() => {
		setCanConfirm((name !== -1) && (type !== -1) && (seats) && (bronze) && (silver) && (gold));
	}, [name, type, seats, bronze, silver, gold, setCanConfirm]);

	return (
			<div className="package-row-inputs mb-4">
				<Dropdown
					id="name"
					value={name}
					onChange={(e) => onChangePackageInput({ name: e.value })}
					dataKey="ParentId"
					options={names}
					optionLabel="DisplayName"
					optionValue="ParentId"
				/>
				<Dropdown
					id="type"
					value={type}
					onChange={(e) => onChangePackageInput({ type: e.value })}
					options={types}
					optionLabel="value"
					optionValue="id"
				/>
				<InputNumber
					id="seats"
					useGrouping={false}
					min={0}
					max={9999}
					onChange={e => onChangePackageInput({ seats: e.value })}
					value={seats}
				/>
				<InputNumber
					id="bronze"
					mode="currency"
					currency="GBP"
					locale="en-GB"
					placeholder="&pound;0.00"
					onChange={e => onChangePackageInput({ bronze: e.value })}
					value={bronze}
				/>
				<InputNumber
					id="silver"
					mode="currency"
					currency="GBP"
					locale="en-GB"
					placeholder="&pound;0.00"
					onChange={e => onChangePackageInput({ silver: e.value })}
					value={silver}
				/>
				<InputNumber
					id="gold"
					mode="currency"
					currency="GBP"
					locale="en-GB"
					placeholder="&pound;0.00"
					onChange={e => onChangePackageInput({ gold: e.value })}
					value={gold}
				/>
			</div>
	);
};

export default BillableResellerPackageRow;