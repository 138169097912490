import { InputText } from "primereact/inputtext";
import { Checkbox } from "primereact/checkbox";
import Button, { ButtonType } from "../../../components/Button";
import { faCirclePlus } from "@fortawesome/free-solid-svg-icons";

const PackageEditorSection = ({ selectedPackage, selectedPackageObj, setSelectedPackageObj, selectedPackageRules, handleCancel, handleAddPackage, addPackageValid }) => {

  // Checkbox change handler for additional offers
  const handleAdditionalOfferChange = (offer) => (checked) => {
    setSelectedPackageObj(prevState => {
      const updatedAdditionalOffers = checked
        ? [...prevState.additionalOffers, offer]
        : prevState.additionalOffers.filter(item => item.Id !== offer.Id);

      return {
        ...prevState,
        additionalOffers: updatedAdditionalOffers,
      };
    });
  };

  // Handle seats change function
  const handleSeatsChange = (value) => {
    setSelectedPackageObj(prevState => ({
      ...prevState,
      seats: parseInt(value) || 0
    }));
  };

  // Handle package level change function
  const handlePackageLevelChange = (value) => {
    setSelectedPackageObj(prevState => ({
      ...prevState,
      packageLevel: value
    }));
	};

	return (
		<>
			{selectedPackage && selectedPackageObj && (
				<div className="addPackage">
					<h4 className="title-top-margin">Package Details</h4>
					<div>
						<p>
							<InputText
								value={selectedPackageObj.seats.toString()}
								onChange={(e) => handleSeatsChange(e.target.value)}
							/>
							&nbsp; blocks of {selectedPackageObj.seatBlockSize} seats ({selectedPackageObj.seats * selectedPackageObj.seatBlockSize})
						</p>
					</div>
					{selectedPackageRules && selectedPackageRules.Levels && (
						<div className="radioButtons-grid">
							{selectedPackageRules.Levels.map(level => (
								<div key={level.Id}>
									<input
										type="radio"
										id={level.Value}
										name="packageLevel"
										value={level.Id}
										checked={selectedPackageObj.packageLevel === level.Id}
										onChange={() => handlePackageLevelChange(level.Id)}
									/>
									<label htmlFor={level.Value}>{level.Value}</label>
								</div>
							))}
						</div>
					)}
					{selectedPackageRules && selectedPackageRules.AdditionalOffers && (
						<div className="checkBoxes-grid">
							{selectedPackageRules.AdditionalOffers.map(offer => (
								<div key={"offer-" + offer.Id}>
									<Checkbox
										inputId={offer.Value}
										checked={selectedPackageObj.additionalOffers.some(item => item.Id === offer.Id)}
										onChange={(e) => handleAdditionalOfferChange(offer)(e.checked)}
									/>
									<label htmlFor={offer.Value}>{offer.Value}</label>
								</div>
							))}
						</div>
					)}
					<div className="package-buttons">
						<Button type={ButtonType.Tertiary} label={"Cancel"} onClick={handleCancel} />
						<Button type={ButtonType.Primary} label={"Add Package"} icon={faCirclePlus} onClick={handleAddPackage} disabled={!addPackageValid} />
					</div>
				</div>
			)}
		</>
	);
};

export default PackageEditorSection;