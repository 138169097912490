import { useState } from "react";
import { faCirclePlus } from "@fortawesome/free-solid-svg-icons";
import { formatDate } from "../../utils/formatting";
import { getUserResellerType, ResellerType } from "../../utils/getUserInformation";
import useFetchAuto from "../../hooks/useFetchAuto";
import useLocalStorage from "../../hooks/useLocalStorage";
import { useSession, LogoutReason } from "../Auth/hooks/useSession";
import AuthLogoutMessage from "../Auth/components/AuthLogoutMessage";
import LoadingSpinner from "../../components/loadingSpinner/LoadingSpinner";
import DataTable from "../../components/DataTable";
import Button, { ButtonType } from "../../components/Button";
import AddReseller from "./AddReseller";
import ViewReseller from "./ViewReseller";
import EditReseller from "./EditReseller";

const ListResellers = () => {
	const { reason } = useSession();
	const [master, setMaster] = useState(false);
	useLocalStorage("ap-liu", () => {
		setMaster(getUserResellerType() === ResellerType.iTEL);
	});
	const [showAddModal, setAddModal] = useState(false);
	const [showViewModal, setViewModal] = useState(false);
	const [editing, setEditing] = useState(false);
	const [viewItemData, setViewItemData] = useState({});
	const [packageData, setPackageData] = useState(false);
	const [fetch, setFetch] = useState(true);
	const { loading, data } = useFetchAuto("/api/Resellers/", null, fetch);

	const onClickReseller = (adding, item) => {
		if (adding) {
			setAddModal(true);
		}
		else {
			setEditing(false);
			setViewItemData(item.data);
			setViewModal(true);
		}
	};

	const columns = [
		{
			field: "Name",
			headerName: "Name",
			class: "col-7",
			valueGetter: (data) => { return (data.Name.length) ? data.Name : <em className="grey">Invite pending:&nbsp;{data.PrimaryContactEmail}</em>; }
		},
		{
			field: "Customers",
			headerName: "Customers",
			valueGetter: (data) => { return data.Customers !== -1 ? data.Customers : "-"; }
		},
		{
			field: "SubResellers",
			headerName: "Sub-Resellers",
			valueGetter: (data) => { return data.SubResellers !== -1 ? data.SubResellers : "-"; }
		},
		{
			field: "CreationDate",
			headerName: "Date Created",
			valueGetter: (data) => { return formatDate(data.CreationDate); }
		}
	];

	return (
		<div>
			{(reason !== LogoutReason.None)
				? <AuthLogoutMessage reason={reason} />
				: (<>
					<h1>{master ? "My Billable Resellers" : "My Resellers"}</h1>
					<p className="mb-6">
						Give permission for your sub-resellers to sell on ucSKILLS training. This will give them access to this Admin Portal and let them provision ucSKILLS
						to their customers.
					</p>
					{loading
						? <LoadingSpinner active={loading} />
						: (<DataTable columns={columns} rows={data} columnIdName="Id" onRowClick={(params) => onClickReseller(false, params)} />)
					}
					<Button type={ButtonType.Secondary} icon={faCirclePlus} label={"Add New Reseller"} onClick={() => onClickReseller(true)} />
					{showAddModal && <AddReseller showModal={showAddModal} setModal={setAddModal} setFetchParentData={setFetch} master={master} />}
					{showViewModal &&
						(editing ?
							<EditReseller
								open={editing}
								setOpenEditor={setEditing}
								showModal={showViewModal}
								setModal={setViewModal}
								master={master}
								resellerId={viewItemData.Id}
								items={packageData}
								setItem={setViewItemData}
							/> :
							<ViewReseller
								open={!editing}
								setOpenEditor={setEditing}
								showModal={showViewModal}
								setModal={setViewModal}
								master={master}
								item={viewItemData}
								setFetchParentData={setFetch}
								setPackageData={setPackageData}
							/>
						)
					}
				</>)
			}
		</div>
	);
};

export default ListResellers;