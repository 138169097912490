import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleExclamation, faCircleInfo, faCircleQuestion, faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import "./Alert.scss";

/**
 * @readonly
 * @enum {number}
 */
export const AlertType = {
	Information: 1,
	Warning: 2,
	Error: 3,
	Question: 4
};

/**
 * Properties Object definition used in the Alert component.
 * @typedef {Object} AlertProps
 * @property {AlertType} type
 * @property {string} children
 */

/**
 * @component
 * @param {AlertProps} props
 */
const Alert = (props) => {
	const typeMap = {
		[AlertType.Information]: { id: "alert-information", icon: faCircleInfo },
		[AlertType.Warning]: { id: "alert-warning", icon: faTriangleExclamation },
		[AlertType.Error]: { id: "alert-error", icon: faCircleExclamation },
		[AlertType.Question]: { id: "alert-question", icon: faCircleQuestion },
  };
	const item = typeMap[props.type];

	return (
		<div id={item.id} className="ap-alert">
			<FontAwesomeIcon id="alert-icon" icon={item.icon} />
			<p className="alert-message">{props.children}</p>
		</div>
  );
};

export default Alert;