import { LogLevel } from "@azure/msal-browser";

/**
 * Enter here the user flows and custom policies for your B2C application
 * This is so the application is deployment agnostic (using the window.env.appEnvironment variable).
 * To learn more about user flows, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/user-flow-overview
 * To learn more about custom policies, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/custom-policy-overview
 */
export function getB2CUserFlowsAndPolicies() {
	const authorityDomain = window.env.instance.substring(8);
	return {
		names: {
			signIn: "B2C_1_si",
			forgotPassword: "B2C_1_pr"
		},
		authorities: {
			signIn: {
				authority: window.env.instance + "/" + window.env.domain + "/b2c_1_si",
			},
			forgotPassword: {
				authority: window.env.instance + "/" + window.env.domain + "/b2c_1_pr",
			}
		},
		authorityDomain: authorityDomain
	};
}

/**
 * Function wrapper of the configuration object to be passed to MSAL instance on creation.
 * This is so the application is deployment agnostic (using the window.env.clientId variable).
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md 
 */
export function getMsalConfig() {
	const b2cPolicies = getB2CUserFlowsAndPolicies();
	return {
		auth: {
			clientId: window.env.clientId,
			authority: b2cPolicies.authorities.signIn.authority,
			knownAuthorities: [b2cPolicies.authorityDomain],
			redirectUri: "/",
			postLogoutRedirectUri: "/",
			navigateToLoginRequestUrl: false,
		},
		cache: {
			cacheLocation: "localStorage",
			storeAuthStateInCookie: false, // Set this to "true" for issues on Edge
		},
		system: {
			loggerOptions: {
				loggerCallback: (level, message, containsPii) => {
					if (containsPii) {
						return;
					}
					switch (level) {
						case LogLevel.Error:
							console.error(message);
							return;
						case LogLevel.Warning:
							console.warn(message);
							return;
						default:
							return;
					}
				},
			},
		},
	};
}

export function getReadScope() {
	return ["https://" + window.env.domain + "/" + window.env.appIdUri + "/read"];
};

export function getWriteScope() {
	return ["https://" + window.env.domain + "/" + window.env.appIdUri + "/write"];
};

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * This is so the application is deployment agnostic (using the window.env.appEnvironment and window.env.appIdUri variables).
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit: 
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 * Add here the endpoints and scopes when obtaining an access token for protected web APIs. For more information, see:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/resources-and-scopes.md
 */
export function getScopes() {
	return {
		scopes: [
			...getReadScope(),
			...getWriteScope()
		]
	};
};
