import { useEffect } from "react";
import PackageHeaderColumns from "../../../components/PackageHeaderColumns";
import Row from "../../../components/Row";
import { getPackageDisplayName, SummaryText } from "../../../utils/resellerPackageUtils";
import PackageDataRow from "./PackageDataRow";

const BillableResellerSummary = ({ businessName, userFirstName, userLastName, userEmail, packages, packageData, setCanSubmit }) => {
	useEffect(() => {
		setCanSubmit(true);
	}, [setCanSubmit]);

	return (
		<div>
			<p>
				{SummaryText}
				<br />
				The Reseller will be able to assign training to their customers for the below packages.
			</p>
			<Row class="my-4">
				<div className="ap-col col-2">
					<h4 className="no-margin">Details</h4>
				</div>
				<div className="ap-col font_s">{businessName.value}</div>
				<div className="ap-col font_s">{userFirstName.value} {userLastName.value}<br />{userEmail.value}</div>
			</Row>
			<Row class="mb-4">
				<div className="ap-col col-2">
					<h4 className="no-margin">Packages</h4>
				</div>
				<div className="ap-col">
					<Row class="pb-2 font_s_bold">
						<PackageHeaderColumns editable={false} showSeatsAndPrices={true} />
					</Row>
					<>
						{packages.map((record, index) =>
							<PackageDataRow
								key={index}
								index={index}
								packageName={getPackageDisplayName(packageData, record.name)}
								pup={record.type}
								seats={record.seats}
								bronze={record.bronze}
								silver={record.silver}
								gold={record.gold}
							/>
						)}
					</>
				</div>
			</Row>
		</div>
	);
};

export default BillableResellerSummary;