import { faCirclePlus } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import { useSession, LogoutReason } from "../Auth/hooks/useSession";
import AuthLogoutMessage from "../Auth/components/AuthLogoutMessage";
import Alert, { AlertType } from "../../components/Alert";
import Button, { ButtonType } from "../../components/Button";
import LoadingSpinner from "../../components/loadingSpinner/LoadingSpinner";
import Modal from "../../components/modals/Modal";
import MessageModal, { MessageModalType } from "../../components/modals/MessageModal";
import useFetch from "../../hooks/useFetch";
import { getFetchOptions } from "../../utils/fetch";
import ProvisionCompany from "./components/ProvisionCompany";
import ProvisionedTable from "./components/ProvisionedTable";
import ProvisioningSearch from "./components/ProvisioningSearch";
import UnprovisionedTable from "./components/UnprovisionedTable";
import "./Provisioning.scss";

const Provisioning = () => {
	const { reason } = useSession();
	const [searchTerm, setSearchTerm] = useState(null);
	const [users, setUsers] = useState([]);
	const [data, setData] = useState(null);
	const [offerRules, setOfferRules] = useState(null);
	const [fatalError, setFatalError] = useState(false);
	const [showProvisionModal, setShowProvisionModal] = useState(null);
	const [showSelectedPackages, setShowSelectedPackages] = useState(false);
	const [editing, setEditing] = useState(false);
	const [provisioningCustomer, setProvisioningCustomer] = useState('');
	const [addPackageValid, setAddPackageValid] = useState(false);
	const [submitEnabled, setSubmitEnabled] = useState(false);
	const { ready, execute, executingFetch, error } = useFetch();
	const [packagesToAdd, setPackagesToAdd] = useState([]);
	const [marketingConsent, setMarketingConsent] = useState(true);
	const [deleteData, setDeleteData] = useState(null);
	const [provisionConfirmation, setProvisionConfirmation] = useState(false);
	const [reload, setReload] = useState(false);
	
	useEffect(() => {
		if (ready && (searchTerm || reload)) {
			const options = getFetchOptions({
				method: "GET"
			});
			options.headers["ucSKILLS-Provisioning-Search"] = searchTerm;
			execute("/api/Provisioning/Search/", options, (error, data) => {
				if (error) {
					setFatalError(true);
					console.error(error.message);
				}
				else {
					setFatalError(false);
					setData(data || { CrmAccounts: [], ProvisionedCompanies: [] });
				}
			});
			if (!reload) {
				execute("/api/Provisioning/OfferRules/", options, (error, offerRules) => {
					if (error) {
						setFatalError(true);
						console.error(error.message);
					}
					else {
						setFatalError(false);
						setOfferRules(offerRules || { offerRules: [] });
					}
				});
			}
			setReload(false);
		}
	}, [searchTerm, reload, ready, execute]);

	const handleCancel = () => {
		setPackagesToAdd([]);
		setUsers([]);
		setMarketingConsent(true);
		setShowProvisionModal(false);
		setShowSelectedPackages(false);
	};
	
	const handleCloseModal = () => {
		setProvisionConfirmation(false);
		setReload(true);
	};

	// Provision.
	const handleProvisionCompany = (enable) => {
		let companyToProvision = {
			"ProvisionId": provisioningCustomer.accountid,
			"Name": provisioningCustomer.name
		};
		if (!editing) {
			companyToProvision.Address = provisioningCustomer.address1_composite;
		}

		const usersToProvision = users.map((curr) => {
			return {
				"FirstName": curr.FirstName,
				"LastName": curr.LastName,
				"ProvisionId": curr.ProvisionId,
				"Username": curr.Username,
			};
		});

		const packagesToProvision = packagesToAdd.map((curr) => {
			return {
				"Id": curr.packageId,
				"SeatBlocks": curr.seats,
				"Level": curr.packageLevel,
				"AdditionalOfferIds": curr.additionalOffers.map(curr => curr.Id),
			};
		});

		const options = getFetchOptions({
			method: "POST",
			body: {
				"Account": companyToProvision,
				"Contacts": usersToProvision,
				"Packages": packagesToProvision,
				"MarketingFlag": marketingConsent ? 1 : 0,
			}
		});

		execute("/api/Provisioning/Company/" + editing, options, (data, err) => {
			if (err) {
				setFatalError(true);
			}
			setShowSelectedPackages(false);
			setPackagesToAdd([]);
			setUsers([]);
			setShowProvisionModal(false);
			if (!editing) {
				setProvisionConfirmation(true);
			}
			enable();
		});		
	};
	
	// Unprovision.
	const handleConfirmDelete = (yes) => {
		if (yes) {
			const toDelete = data.ProvisionedCompanies.find(x => x.ProvisionId === deleteData.accountid);
			if (toDelete) {
				const options = getFetchOptions({
					method: "DELETE"
				});
				execute("/api/Provisioning/Company/" + toDelete.Id, options, (data, err) => {
					if (err) {
						setFatalError(true);
					}
					setShowSelectedPackages(false);
					setPackagesToAdd([]);
					setUsers([]);
					setShowProvisionModal(false);
					setReload(true);
				});
			}
		}
		setDeleteData(null);
	};
	
	const provisionContent = (
		<>
			<ProvisionCompany
				editing={editing}
				packagesToAdd={packagesToAdd}
				setPackagesToAdd={setPackagesToAdd}
				setAddPackageValid={setAddPackageValid}
				showSelectedPackages={showSelectedPackages}
				setShowSelectedPackages={setShowSelectedPackages}
				customer={provisioningCustomer}
				offerRules={offerRules}
				marketingConsent={marketingConsent}
				setMarketingConsent={setMarketingConsent}
				users={users}
				setUsers={setUsers}
				setSubmitEnabled={setSubmitEnabled}
				addPackageValid={addPackageValid}
			/>
		</>
	);

	const provisionButtons = (
		<>
			{editing && <Button type={ButtonType.Secondary} label={"Delete"} onClick={() => setDeleteData(provisioningCustomer)} />}
			<Button type={ButtonType.Tertiary} label={"Cancel"} onClick={handleCancel} />
			<Button
				type={ButtonType.Primary}
				label={!editing ? "Provision": "Update"}
				icon={faCirclePlus}
				disabled={!submitEnabled}
				onClick={(e, c) => handleProvisionCompany(c)}
				disableUntilClickCompleted={true}
			/>
		</>
	);
	
	const DeleteModal = (
		<div key="DeleteModal">
			{(deleteData) &&
				<MessageModal title="Confirm ucSKILLS Company Deletion" type={MessageModalType.Question} onClick={handleConfirmDelete} size="m">
					Are you sure you want to delete '{deleteData.name}'?
				</MessageModal>
			}
		</div>
	);

	const renderMainContent = () => {
		if (reason !== LogoutReason.None) {
			return <AuthLogoutMessage reason={reason} />;
		}
		if (executingFetch) {
			return <LoadingSpinner active={executingFetch} />;
		}
		if (provisionConfirmation) {
			return <MessageModal title="ucSKILLS Company Provisioned" type={MessageModalType.Information} onClick={handleCloseModal} size="m">
				The Company '{provisioningCustomer.name}' has been provisioned.<br/>Each registered User has been sent a welcome email.
			</MessageModal>
		}
		else if (data) {
			let result = [
				<UnprovisionedTable
					key="UnprovisionedTable"
					unprovisioned={data.CrmAccounts || []}
					setShowModal={setShowProvisionModal}
					setEditing={setEditing}
					setProvisioningCustomer={setProvisioningCustomer}
				/>,
				<ProvisionedTable
					key="ProvisionedTable"
					provisioned={data.ProvisionedCompanies || []}
					setShowModal={setShowProvisionModal}
					setEditing={setEditing}
					setShowSelectedPackages={setShowSelectedPackages}
					setProvisioningCustomer={setProvisioningCustomer}
					setPackagesToAdd={setPackagesToAdd}
					setMarketingConsent={setMarketingConsent}
					rules={offerRules}
				/>
			];
			if (showProvisionModal) {
				result.push(
					<Modal
						key="Modal" 
						title={`Provisioning - ${provisioningCustomer.name}`}
						showModal={showProvisionModal}
						content={provisionContent}
						size="m"
						modalButtons={provisionButtons}
						close={handleCancel}
					/>
				);
			}
			if (deleteData) {
				result.push(DeleteModal);
			}
			return result;
		}
		return <></>;
	};
	
	return (
		<>
			<h1>Provisioning</h1>
			<ProvisioningSearch searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
			{(error || fatalError) && <Alert type={AlertType.Error}>Error: Please try again. If the problem persists, please contact the development department.</Alert>}
			{renderMainContent()}
		</>
	);
};

export default Provisioning;