import { useEffect, useState } from "react";
import { faPencil, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { useSession, LogoutReason } from "../Auth/hooks/useSession";
import AuthLogoutMessage from "../Auth/components/AuthLogoutMessage";
import { getFetchOptions } from "../../utils/fetch.js";
import { formatDate } from "../../utils/formatting.js";
import useFetchAuto from "../../hooks/useFetchAuto";
import useFetch from "../../hooks/useFetch";
import Modal from "../../components/modals/Modal";
import MessageModal, { MessageModalType } from "../../components/modals/MessageModal";
import Button, { ButtonType } from "../../components/Button";
import PackageHeaderColumns from "../../components/PackageHeaderColumns";
import PackageDataRow from "./components/PackageDataRow";
import LoadingSpinner from "../../components/loadingSpinner/LoadingSpinner";
import "../../assets/scss/_imports.scss";

const ViewReseller = (props) => {
	const { reason } = useSession();
	const [canDelete, setCanDelete] = useState(false);
	const [confirmDelete, setConfirmDelete] = useState(false);
	const [confirmResend, setConfirmResend] = useState(false);
	const [deleting, setDeleting] = useState(false);
	const { loading, data } = useFetchAuto("/api/ResellerById/" + props.item.Id);
	const { ready, execute, executingFetch } = useFetch();

	// Effect to check each package deletion flag and set the Reseller deletion flag.
	useEffect(() => {
		if ((!loading) && (data)) {
			const anyAssigned = data.Packages.some((x) => !x.CanDelete);
			setCanDelete(data.SubResellers === 0 && !anyAssigned);
		}
	}, [loading, data]);

	// Perform REST request.
	useEffect(() => {
		if (ready && deleting) {
			props.setFetchParentData(false);
			const options = getFetchOptions({
				method: "DELETE"
			});
			execute("/api/Reseller/" + props.item.Id, options, (error) => {
				setDeleting(false);
				if (error) {
					console.error(error.message);
				}
				props.setFetchParentData(true);
				props.setModal(false);
			});
		}
	}, [ready, props.item, deleting, execute]);

	function onClickClose(modal) {
		props.setFetchParentData(true);
		props.setModal(modal);
	}

	function onClickEdit() {
		props.setFetchParentData(false);
		props.setPackageData(data.Packages);
		props.setOpenEditor(true);
	}

	function onClickSendEmail() {
		setConfirmResend(true);
	}

	function onClickConfirmResend(yes) {
		if (yes) {
			const options = getFetchOptions({
				method: "POST",
				body: {
					B2CId: data.ContactB2CId,
					FirstName: data.ContactFirstName,
					LastName: data.ContactLastName,
					Email: data.ContactEmail
				}
			});
			execute("/api/ResendCompleteRegistrationEmail", options, (error) => {
				if (error) {
					console.error(error.message);
				}
				else {
					props.setModal(false);
				}
			});
		}
		setConfirmResend(false);
	}

	function onClickDelete() {
		setConfirmDelete(true);
	}

	function onClickConfirmDelete(yes) {
		if (yes) {
			setDeleting(true);
		}
		setConfirmDelete(false);
	}

	const ViewContent = (
		<>
			{loading
				? <LoadingSpinner active={loading} />
				: data &&
				<div>
					<h4 className="no-margin">Details</h4>
					<div className="details-row">
						<p>{(data.Name) ? data.Name : <em className="grey">Invite pending</em>}</p>
						<p>{data.ContactFirstName} {data.ContactLastName}<br />{data.ContactEmail}</p>
						<p>Registration Email Sent Date<br />{formatDate(data.ContactLastRegistrationEmailDate)}</p>
					</div>
					<h4 className="no-margin mb-3">Packages</h4>
					{props.master ?
						<>
							<PackageHeaderColumns editable={false} showSeatsAndPrices={true} />
							{data.Packages.map((record, index) =>
								<PackageDataRow
									key={index}
									index={index}
									packageName={record.Name}
									pup={record.PUP ? 0 : 1}
									seats={record.Seats}
									bronze={record.BronzePrice}
									silver={record.SilverPrice}
									gold={record.GoldPrice}
								/>
							)}
						</>
						:
						<div className="font_s">
							{data.Packages.map((record, index) =>
								<div className="package-row-small grey-row" key={index}>
									<p>{record.Name}</p>
								</div>
							)}
						</div>
					}
				</div>
			}
		</>
	);

	const ViewButtons = (
		<div className="button-grid">
			<Button type={ButtonType.Secondary} icon={faPencil} label={"Edit Packages"} onClick={onClickEdit} className={"mr-2"} />
			{(!data || !data.ContactLastLoginDate) && <Button type={ButtonType.Secondary} icon={faEnvelope} label={"Resend Welcome Email"} onClick={onClickSendEmail} />}
			{canDelete && <Button type={ButtonType.Tertiary} label={"Delete"} onClick={onClickDelete} />}
		</div>
	);

	const DeleteModal = (
		<>
			{(data && ("Name" in data)) &&
				<MessageModal title="Confirm Reseller Deletion" type={MessageModalType.Question} onClick={onClickConfirmDelete} size="m">
					Are you sure you want to delete '{(data.Name) ? data.Name : <em className="grey">Invite pending</em>}'?
				</MessageModal>
			}
		</>
	);

	const ResendModal = (
		<>
			{(data && data.ContactFirstName) &&
				<MessageModal title="Confirm Resend" type={MessageModalType.Question} onClick={onClickConfirmResend} size="m">
					Are you sure you want to resend Welcome Email to {data.ContactFirstName} {data.ContactLastName}?
				</MessageModal>
			}
		</>
	);

	const ViewModal = (
		<Modal
			title="Reseller Details"
			showModal={props.showModal}
			content={ViewContent}
			size="m"
			modalButtons={ViewButtons}
			close={onClickClose}
		/>
	);

	const render = () => {
		if (reason !== LogoutReason.None) {
			return <AuthLogoutMessage reason={reason} />;
		}
		if (executingFetch) {
			return <LoadingSpinner active={executingFetch} />;
		}
		if (confirmDelete) {
			return DeleteModal;
		}
		if (confirmResend) {
			return ResendModal;
		}
		if (data) {
			return ViewModal;
		}
		return <></>;
	}

	return (
		<>{render()}</>
	);
};

export default ViewReseller;