import { useEffect } from "react";
import useFetch from "./useFetch";

/**
 * @typedef {Object} FetchState
 * @property {boolean} loading
 * @property {{status: number, message: string}} error
 * @property {string} data
 */

/**
 * This hook wraps the useFetch hook to perform requests automatically, typically GET requests on page renders.
 */
const useFetchAuto = (url, options, fetch = true) => {
  const { ready, data, error, execute, executingFetch } = useFetch();
  useEffect(() => {
    if ((ready) && (fetch)) {
      execute(url, options);
    }
  }, [ready, url, options, fetch]); // Missing dependency for "execute" here - which causes an infinite loop when included

  return {
    loading: executingFetch,
    error,
    data
  };
};

export default useFetchAuto;