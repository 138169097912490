import { useEffect } from "react";
import { Dialog } from "primereact/dialog";
import ModalCloseButton from "./ModalCloseButton";
import "./Modal.scss";
import { getSizeClass } from "./ModalSizeClasses";

const Modal = ({ title, showModal, content, size, modalButtons, close }) => {
	const header = (
		<div className="modal-header">
			<ModalCloseButton close={close} />
			<h1>{title}</h1>
		</div>
	);

	const sizeClass = getSizeClass(size);

	useEffect(() => {
		if (showModal) {
			document.body.classList.add("body-no-scroll");
		} else {
			document.body.classList.remove("body-no-scroll");
		}
		return () => {
			document.body.classList.remove("body-no-scroll");
		};
	}, [showModal]);

	if (content) {
		return (
			<Dialog id="uc-modal" className={sizeClass} position="center" closable={false} visible={showModal} header={header} footer={modalButtons} onHide={close}>
				<hr />
				<div className="body">
					{content}
				</div>
				<hr />
			</Dialog>
		);
	} else {
		title = "No Modal Content";
		return (
			<Dialog position="center" closable={false} visible={showModal} header={header} footer={modalButtons} onHide={close} />
		);
	}
};

export default Modal;
