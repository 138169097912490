import React, { useRef, useState, useEffect } from "react";
import { Calendar } from "primereact/calendar";
import { FileUpload } from "primereact/fileupload";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { useSession, LogoutReason } from "../Auth/hooks/useSession";
import AuthLogoutMessage from "../Auth/components/AuthLogoutMessage";
import { getFetchOptions } from "../../utils/fetch";
import useFetch from "../../hooks/useFetch";
import useFileHandler from "../../hooks/useFileHandler";
import LoadingSpinner from "../../components/loadingSpinner/LoadingSpinner";
import Button, { ButtonType } from "../../components/Button";
import MessageModal from "../../components/modals/MessageModal";
import "./Invoices.scss";

const UploadInvoices = (props) => {
	const { reason } = useSession();
	const [date, setDate] = useState(null);
	const fileUploadRef = useRef(null);
	const { fileSelected, selectedFile, errorMessage, triggerFileCheck, resetFileSelection } = useFileHandler(fileUploadRef);
	const isSubmitDisabled = !props.selectedReseller || !date || !fileSelected;
	const { ready, execute, executingFetch, error } = useFetch();
	const [showErrorModal, setErrorModal] = useState(false);
	const [showSuccessModal, setSuccessModal] = useState(false);
	const [successMessage, setSuccessMessage] = useState("");
	const [fetchError, setFetchError] = useState("");

	const onUpload = () => {
		setSuccessModal(true);
	};

	const handleFileChange = () => {
		triggerFileCheck();
	};

	useEffect(() => {
		if (errorMessage) {
			setErrorModal(true);
		}
	}, [errorMessage]);

	const onClickSubmit = (enable) => {
		if (ready && selectedFile) {
			const formData = new FormData();
			formData.append("File", selectedFile);
			formData.append("ResellerId", props.selectedReseller.Id);
			formData.append("InvoiceDate", date.toUTCString());
			const options = getFetchOptions({
				method: "POST",
				body: formData,
				contentType: null
			});
			execute("/api/UploadInvoice", options, (error) => {
				if (error) {
					setFetchError(error.message); // Update the error message state
					setErrorModal(true);
				}
				else {
					setSuccessMessage("Invoice successfully uploaded."); // Update the success message state
					onUpload();
				}
				enable();
			});
		}
	};

	function onCloseModal() {
		window.location.reload();
	}

	const ErrorModalContent = errorMessage ? errorMessage : fetchError;

	const ErrorModal = (
		<MessageModal title="Error" onClick={onCloseModal} size="m">
			<p>{ErrorModalContent}</p>
		</MessageModal>
	);
	const SuccessModal = (
		<MessageModal title="Success" onClick={onCloseModal} size="m">
			<p>{successMessage}</p>
		</MessageModal>
	);

	const render = () => {
		if (reason !== LogoutReason.None) {
			return <AuthLogoutMessage reason={reason} />;
		}
		if (executingFetch) {
			return <LoadingSpinner active={executingFetch} />;
		}
		return (
			<div className="uploadInvoice">
				<div className="mb-4 invoiceGrid">
					<label>Date of Invoice</label>
					<Calendar placeholder="--/--/----" value={date} onChange={(e) => setDate(e.value)} dateFormat="dd/mm/yy" showIcon />
				</div>
				<div className="mb-4 invoiceGrid">
					<label>Upload File</label>
					{fileSelected ? (
						<>
							<button className="btn-secondary" onClick={resetFileSelection}><span className="upload-remove-button">{selectedFile.name}</span> <FontAwesomeIcon icon={faTrashCan} /></button>
						</>
					) : (
						<FileUpload
							ref={fileUploadRef}
							className="font_s upload-button flex-end"
							mode="basic"
							name="fileUpload"
							accept="pdf/*"
							maxFileSize={1000000}
							onUpload={onUpload}
							onChange={handleFileChange} />
					)}
				</div>
				<div className="mb-4 invoiceGrid">
					<div></div>
					<Button
						type={ButtonType.Primary}
						label="Submit"
						disabled={isSubmitDisabled}
						onClick={(e, c) => onClickSubmit(c)}
						disableUntilClickCompleted={true}
					/>
				</div>
				{showErrorModal && ErrorModal}
				{showSuccessModal && SuccessModal}
			</div>
		);
	}

	return (
		<>{render()}</>
	)
}

export default UploadInvoices;
