import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./Button.css";

/**
 * @readonly
 * @enum {number}
 */
export const ButtonType = {
	Primary: 1,
	Secondary: 2,
	Tertiary: 3
};

/**
 * Properties Object definition used in the Button component.
 * @typedef {Object} ButtonProps
 * @property {ButtonType} type
 * @property {type} icon
 * @property {boolean} iconRight
 * @property {string} label
 * @property {boolean} disabled
 * @property {Function} onClick
 */

/**
 * @component
 * @param {ButtonProps} props
 */
const Button = (props) => {
	const typeMap = {
		[ButtonType.Primary]: "btn-primary",
		[ButtonType.Secondary]: "btn-secondary",
		[ButtonType.Tertiary]: "btn-tertiary"
	};
	const [handlingClickEvent, setHandlingClickEvent] = useState(false);

	// Prevent multiple clicks while previous click is processing.
	function onClick(e) {
		if (handlingClickEvent) {
			return;
		}
		setHandlingClickEvent(true);
		if (props.disableUntilClickCompleted) {
			props.onClick(e, setHandlingClickEvent);
		}
		else {
			props.onClick(e);
			setHandlingClickEvent(false);
		}
	}

	let className = typeMap[props.type];
	if (props.disabled || handlingClickEvent) {
		className += " disabled";
	}

	return (
		<button className={className} onClick={onClick}>
			{(!props.iconRight && props.icon) && <FontAwesomeIcon icon={props.icon} />}
			<span>{props.label}</span>
			{(props.iconRight && props.icon) && <FontAwesomeIcon icon={props.icon} />}
		</button>
	);
};

export default Button;