import { useEffect } from "react";
import Alert, { AlertType } from "../../../components/Alert";
import UserInputs from "../../../components/UserInputs";

const MemberDetails = ({ userFirstName, userLastName, userEmail, initialData, onChangeInput, setCanSubmit, title }) => {
	useEffect(() => {
		let submit = ((userFirstName.value.trim().length > 0) && (userLastName.value.trim().length > 0) && (userEmail.value.trim().length > 0));
		if ((submit) && (initialData)) {
			submit &= (
				(initialData.userFirstName.value.localeCompare(userFirstName.value) !== 0) ||
				(initialData.userLastName.value.localeCompare(userLastName.value) !== 0) ||
				(initialData.userEmail.value.localeCompare(userEmail.value) !== 0)
			);
		}
		setCanSubmit(submit);
	}, [initialData, userFirstName.value, userLastName.value, userEmail.value, setCanSubmit]);

	return (
		<div>
			{(!title.startsWith("Edit")) && <Alert type={AlertType.Warning}>
				Warning: This will create a new team member who has the same level of access as you for this portal. They will be able to see the same details and
				perform the same actions as yourself. Please make sure you are entering the correct details and that the recipient is expecting this email.
			</Alert>}
			<UserInputs 
				nameLabel="Team Member Name"
				firstName={userFirstName}
				lastName={userLastName}
				emailLabel="Team Member Email"
				email={userEmail}
				onChangeInput={onChangeInput}
			/>
		</div>
	);
};

export default MemberDetails;