import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { formatPrice } from "../../../utils/formatting";
import PackageHeaderColumns from "../../../components/PackageHeaderColumns";
import { TYPES } from "../../../utils/resellerPackageUtils";
import "./../GetStarted.css";

const Packages = ({ packages }) => {
	const showSeatsAndPrices = false;
	const groupedPackages = packages.reduce((out, curr) => {
		out[curr.ParentName] = out[curr.ParentName] || [];
		out[curr.ParentName].push(curr);
		return out;
	}, Object.create(null));

	function setPriceItem(value) {
		if (value) {
			return "\u00A3" + formatPrice(value);
		}
		else {
			return (<FontAwesomeIcon icon={faCheck} />);
		}
	}

	return (
		<div>
			<p>
				These are the packages you have available to provision to your customers. If these are incorrect please contact iTEL or the supplier so they can amend
				them.
			</p>
			<>
				{groupedPackages && Object.keys(groupedPackages).map((key) => {
					return (
						<div key={key} className="package-by-reseller">
							<h4 className="pb-2">{key}</h4>
							<PackageHeaderColumns editable={false} showSeatsAndPrices={showSeatsAndPrices} />
							{groupedPackages[key].map((record, index) =>
								<div key={index} className="package-row grey-row">
									<p>{record.Name}</p>
									<p>{TYPES[record.PUP ? 0 : 1]}</p>
									{showSeatsAndPrices &&
										<>
											<p>{record.PUP ? record.Seats + " minimum" : "Batches of " + record.Seats}</p>
											<p>{setPriceItem(record.BronzePrice)}</p>
											<p>{setPriceItem(record.SilverPrice)}</p>
											<p>{setPriceItem(record.GoldPrice)}</p>
										</>
									}
								</div>
							)}
						</div>
					);
				})}
			</>
		</div>
	);
};

export default Packages;