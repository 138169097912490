import { EventType } from '@azure/msal-browser';
import { getB2CUserFlowsAndPolicies, getScopes } from "./configuration.js";

export function forgotPasswordEventCallback(event, instance) {
	const b2cPolicies = getB2CUserFlowsAndPolicies();
	if ((event.eventType === EventType.LOGIN_SUCCESS || event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) && (event.payload.account)) {
		if ((event.payload.idTokenClaims["tfp"] === b2cPolicies.names.forgotPassword) || (event.payload.idTokenClaims["acr"] === b2cPolicies.names.forgotPassword)) {
		//if (event.payload.idTokenClaims["tfp"] === b2cPolicies.names.forgotPassword) {
			let signInFlowRequest = {
				authority: b2cPolicies.authorities.signIn.authority,
				scopes: getScopes()
			};
			instance.loginRedirect(signInFlowRequest);
		}
	}

	// https://docs.microsoft.com/en-us/azure/active-directory/develop/reference-aadsts-error-codes
	if ((event.eventType === EventType.LOGIN_FAILURE) && (event.error)) {
		// Forgot Password.
		if (event.error.errorMessage.includes("AADB2C90118")) {
			const resetPasswordFlowRequest = {
				authority: b2cPolicies.authorities.forgotPassword.authority,
				scopes: [],
			};
			instance.loginRedirect(resetPasswordFlowRequest);
			return;
		}
		// Forgot Password cancelled.
		if (event.error.errorMessage.includes("AADB2C90091")) {
			instance.loginRedirect();
		}
	}
}