import DataTable from "../../../components/DataTable";
import Button, { ButtonType } from "../../../components/Button";
import { faCirclePlus } from '@fortawesome/free-solid-svg-icons';

const UnprovisionedTable = ({ unprovisioned, setShowModal, setEditing, setProvisioningCustomer }) => {

	const handleProvisionClick = (rowData) => {
		setProvisioningCustomer(rowData);
		setEditing(false);
		setShowModal(true);
	};

	const actionBodyTemplate = (rowData) => {
		return (
			<Button
				type={ButtonType.Secondary}
				icon={faCirclePlus}
				label={"Provision"}
				disabled={!rowData.primarycontactid}
				onClick={() => handleProvisionClick(rowData)}
			/>
		);
	};

	const columns = [
		{
			field: "name",
			headerName: "Customer",
			style: { width: '30%' },
		},
		{
			field: "address1_composite",
			headerName: "Address",
			style: { width: '70%' },
		},
		{
			valueGetter: actionBodyTemplate,
			style: { minWidth: '170px' },
			sortable: false,
		}
	];

	return (
		<>
			<h2>Unprovisioned Customers</h2>
			{unprovisioned.length === 0 ? (
				<div className='noResults'><p>No results</p></div>
			) : (<DataTable columns={columns} rows={unprovisioned} columnIdName="accountid" className="unprovisioned-table" />
			)}
		</>
	);
};

export default UnprovisionedTable;
