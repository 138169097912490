import { useEffect } from "react";

import { SummaryText } from "../../../utils/resellerPackageUtils";
import Row from "../../../components/Row";

const ResellerSummary = ({ userFirstName, userLastName, userEmail, packages, existingReseller, setCanSubmit }) => {
	useEffect(() => {
		setCanSubmit(true);
	}, [setCanSubmit]);

	return (
		<div>
			{!existingReseller ?
				(<p>
					{SummaryText} The Reseller will be able to assign training to their customers for the packages you have assigned them. You will be invoiced by iTEL 
					for these sales.
				</p>) :
				(<p>
					The Reseller you have named already exists in ucSKILLS Admin Portal. When they next log in they will be prompted to accept or decline your invite to sell
					the training packages you have defined on your behalf. They will show as pending in your list until they have accepted your invite.
				</p>)
			}
				<h4 className="no-margin mb-2">Main Contact</h4>
				<div className="font_s mb-3">{userFirstName.value} {userLastName.value}<br />{userEmail.value}</div>
				<h4 className="no-margin mb-2">Packages</h4>
				<ul className="list-none font_s">
					{packages.map((record) =>
						<li key={record.PackageId}>{record.DisplayName}</li>
					)}
				</ul>
		</div>
	);
};

export default ResellerSummary;