import { useEffect } from "react";
import { MultiSelect } from "primereact/multiselect";
import UserInputs from "../../../components/UserInputs";
import Alert, { AlertType } from "../../../components/Alert";

const ResellerDetails = ({ userFirstName, userLastName, userEmail, packages, packageData, onChangeInput, setCanSubmit, title }) => {
	useEffect(() => {
		setCanSubmit(
			(userFirstName.value.trim().length > 0) &&
			(userLastName.value.trim().length > 0) &&
			(userEmail.value.trim().length > 0) &&
			(packages.length)
		);
	}, [userFirstName.value, userLastName.value, userEmail.value, packages, setCanSubmit]);

	return (
		<div>
			{(!title.startsWith("Edit")) &&
				<Alert type={AlertType.Warning}>
					Please add the primary contact for your new reseller. You should confirm with them which email to use. If they already have an account in the Admin
					Portal they will be asked to confirm you as their supplier - otherwise they will invited to create an account.
				</Alert>}
			<UserInputs
				nameLabel="Primary Contact"
				firstName={userFirstName}
				lastName={userLastName}
				emailLabel="Primary Contact Email"
				email={userEmail}
				onChangeInput={onChangeInput}
			/>
			<div className="form-row mt-4">
				<label className="align-self-center" htmlFor="packages">Packages available to Reseller</label>
				<MultiSelect
					id="packages"
					value={packages}
					onChange={(e) => onChangeInput({ packages: e.value })}
					options={packageData}
					optionLabel="DisplayName"
					placeholder="Select Packages"
					maxSelectedLabels={1}
					filter
				/>
			</div>
		</div>
	);
};

export default ResellerDetails;