import { InputText } from "primereact/inputtext";

/**
 * Properties Object definition used in the Input component.
 * @typedef {Object} InputProps
 * @property {string} id
 * @property {{string, number}} data
 * @property {string} placeholder
 * @property {number} maxLength
 * @property {Function} onChange
 * @property {string} feedback
 * @property {string} responsiveClass
 */

/**
 * @component
 * @param {InputProps} props
 */
const InputTextWithValidation = (props) => {
	const inputClassName = props.data.validation === 1 ? "ap-invalid" : "";

	return (
		<>
			<InputText
				id={props.id}
				className={`${props.responsiveClass || ""} ${inputClassName}`}
				value={props.data.value}
				placeholder={props.placeholder}
				maxLength={props.maxLength}
				onChange={(e) => props.onChange(e)}
			/>
			{props.data.validation === 1 && (
				<>
					<div className="ap-invalid-icon">!</div>
					<div className="ap-invalid-feedback">{props.feedback}</div>
				</>
			)}
		</>
	);
};

export default InputTextWithValidation;
