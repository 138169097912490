const Row = (props) => {
	let className = "p-inputgroup ";
	if (props.class) {
		className += " " + props.class;
	}
	return (
		<div className={className}>{props.children}</div>
	);
};

export default Row;