import { useCallback, useEffect, useState } from "react";
import useLocalStorage from "./../../hooks/useLocalStorage";
import { useSession, LogoutReason } from "../Auth/hooks/useSession";
import AuthLogoutMessage from "../Auth/components/AuthLogoutMessage";
import LoadingSpinner from "../../components/loadingSpinner/LoadingSpinner";
import { getFetchOptions } from "../../utils/fetch";
import useFetch from "../../hooks/useFetch";
import useFetchAuto from "../../hooks/useFetchAuto";
import { formatDateWithHyphen } from "../../utils/formatting";
import { getUserResellerType } from "./../../utils/getUserInformation";
import SelectReseller from "../Billing/SelectReseller";
import "./BillingReport.scss";
import MonthSelector from "./components/MonthSelector";
import PackageBilling from "./components/PackageBilling";

const BillingReport = () => {
	const { reason } = useSession();
	const [userType, setUserType] = useState();
	const [selectedReportDate, setSelectedReportDate] = useState();
	const [selectedReseller, setSelectedReseller] = useState();
	const [data, setData] = useState(null);
	const { loadingReport, data: fetchData } = useFetchAuto(selectedReportDate ? "/api/Reports/Billing/" + selectedReportDate + "/" + selectedReseller : null, null)
	const { ready, execute, executingFetch } = useFetch();
	const getDefaultDate = () => {
		const now = new Date();
		return new Date(now.getFullYear(), now.getMonth() - 1, 1);
	};
	const [currentDate, setCurrentDate] = useState(getDefaultDate()); // Define the state here

	const handleDateChange = useCallback((formattedDate) => {
		setSelectedReportDate(formattedDate);
		if (ready && !loadingReport && selectedReseller && formattedDate !== null) {
			const options = getFetchOptions({
				method: "GET"
			});
			const id = selectedReseller.Id != null ? selectedReseller.Id : selectedReseller
			execute("/api/Reports/Billing/" + formattedDate + "/" + id, options, (error, newData) => {
				if (error) {
					console.error(error.message);
				}
				else {
					setData(newData);
				}
			});
		}
	}, [ready, loadingReport, selectedReseller, execute]);

	useEffect(() => {
		const now = new Date();
		const firstDayLastMonth = new Date(now.getFullYear(), now.getMonth() - 1, 1);
		const formattedInitialDate = formatDateWithHyphen(firstDayLastMonth.toDateString(), false, true).split('T')[0];
		setSelectedReportDate(formattedInitialDate);
		setSelectedReseller(0);
	}, []);

	useEffect(() => {
		if (currentDate) {
			const formattedDate = formatDateWithHyphen(currentDate.toDateString(), false, true).split('T')[0];
			handleDateChange(formattedDate);
		}
	}, [currentDate, handleDateChange]);

	useLocalStorage("ap-liu", () => {
		setUserType(getUserResellerType());
	});

	const handleSelectReseller = (reseller) => {
		setSelectedReseller(reseller);
		if (ready && !loadingReport && reseller !== null) {
			const options = getFetchOptions({
				method: "GET"
			});
			execute("/api/Reports/Billing/" + selectedReportDate + "/" + reseller.Id, options, (error, newData) => {
				if (error) {
					console.error(error.message);
				}
				else {
					setData(newData);
				}
			});
		}
	};

	const renderData = data || fetchData;

	const report = renderData && renderData.Package ? <PackageBilling packages={renderData.Package} /> : <></>;

	return (
		<>
			{(reason !== LogoutReason.None)
				? <AuthLogoutMessage reason={reason} />
				: (<>
						<h1>Billing Report</h1>
						{userType === 0 && <SelectReseller onSelectReseller={handleSelectReseller}></SelectReseller>}
						<h2 className="mt-5">Select Month</h2>
						<MonthSelector currentDate={currentDate} onDateChange={setCurrentDate} />
						{loadingReport || executingFetch
							? <LoadingSpinner active={loadingReport} />
							: <><h2 className="mt-5">Reports</h2>{report}</>
						}
					</>)
			}
		</>
	);
};

export default BillingReport;