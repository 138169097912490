import Row from "./Row";
import InputTextWithValidation from "./InputTextWithValidation";

const UserInputs = ({ nameLabel, firstName, lastName, emailLabel, email, onChangeInput }) => {
	return (
		<>
			<div className="form-row">
				<label className="align-self-center" htmlFor="firstName-input">{nameLabel}</label>
					<InputTextWithValidation
						id="firstName-input"
						data={firstName}
						placeholder="First Name"
						maxLength="64"
						onChange={e => onChangeInput({ userFirstName: { value: e.target.value, validation: 0 } })}
						feedback="The first name contains invalid characters."
					/>
					<InputTextWithValidation
						id="lastName-input"
						data={lastName}
						placeholder="Last Name"
						maxLength="64"
						onChange={e => onChangeInput({ userLastName: { value: e.target.value, validation: 0 } })}
						feedback="The last name contains invalid characters."
					/>
			</div>
			<div className="form-row">
				<label className="align-self-center" htmlFor="email-input">{emailLabel}</label>
					<InputTextWithValidation
						id="email-input"
						data={email}
						placeholder="Email"
						maxLength="320"
						onChange={e => onChangeInput({ userEmail: { value: e.target.value, validation: 0 } })}
						feedback="The email address is not valid."
						responsiveClass="col-span-2"
					/>
			</div>
		</>
	);
};

export default UserInputs;