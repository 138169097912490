import { createContext, useContext, useState } from 'react';
import './LoadingSpinner.scss';

const LoadingSpinner = ({ active }) => {
	const overlayClassName = `loading-overlay${active ? ' active' : ''}`;

	return (
		<div className={overlayClassName}>
			<div className="spinner"></div>
		</div>
	);
};

const LoadingContext = createContext();

export const LoadingProvider = ({ children }) => {
	const [isLoading, setIsLoading] = useState(false);

	return (
		<LoadingContext.Provider value={{ isLoading, setIsLoading }}>
			{children}
		</LoadingContext.Provider>
	);
};

export const useLoading = () => {
	return useContext(LoadingContext);
};

export default LoadingSpinner;
