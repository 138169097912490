import { useState } from "react";

const useMultiStepModal = (contents) => {
	const [step, setStep] = useState(0);

	function next() {
		setStep((prev) => {
			if (prev >= contents.length - 1) {
				return prev;
			}
			return ++prev;
		});
	}

	function back() {
		setStep((prev) => {
			if (prev <= 0) {
				return prev;
			}
			return --prev;
		});
	}

	return {
		contents,
		content: contents[step],
		step,
		next,
		back,
		last: step === contents.length - 1
	};
};

export default useMultiStepModal;