import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleArrowLeft, faCircleArrowRight } from "@fortawesome/free-solid-svg-icons";

const MonthSelector = ({ currentDate, onDateChange }) => {
	const changeMonth = (month) => {
		onDateChange((prevDate) => new Date(prevDate.getFullYear(), prevDate.getMonth() + month, 1));
	};

	const isFutureMonth = () => {
		const now = new Date();
		const firstDayOfCurrentMonth = new Date(now.getFullYear(), now.getMonth(), 1);
		const firstDayOfPreviousMonth = new Date(
			now.getFullYear(),
			now.getMonth() - 1,
			1
		);
		return currentDate >= firstDayOfCurrentMonth || currentDate >= firstDayOfPreviousMonth;
	};

	const formatMonth = (date) => {
		if (!date) {
			return "";
		}
		return date.toLocaleString("default", { month: "long", year: "numeric" });
	};

	return (
		<div className="month-selector">
			<button onClick={() => changeMonth(-1)}><FontAwesomeIcon className="fa-xl" icon={faCircleArrowLeft} /></button>
			<p className="no-margin font_m_bold">{formatMonth(currentDate)}</p>
			<button onClick={() => changeMonth(1)} disabled={isFutureMonth()}><FontAwesomeIcon className="fa-xl" icon={faCircleArrowRight} /></button>
		</div>
	);
};

export default MonthSelector;