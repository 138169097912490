import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark, faArrowLeft } from "@fortawesome/free-solid-svg-icons";

const ModalCloseButton = (props) => {
	const toggle = () => {
		if ((!props.step) || (props.step === 0) || (!props.back)) {
			props.close();
		}
		else {
			props.back();
		}
	}

	return (
		<>
			{((!props.step) || (props.step === 0)) ? (
				<button className="icon-btn modal-close-btn" onClick={toggle} type="button">
					{!props.hideClose && <FontAwesomeIcon icon={faXmark} />}
				</button>
			
			) : (
				<button className="icon-btn modal-back-btn" onClick={toggle} type="button">
					<FontAwesomeIcon icon={faArrowLeft} /> 
				</button>
			)}
		</>
	);
};

export default ModalCloseButton;