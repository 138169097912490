import { InputText } from "primereact/inputtext";
import { useState } from "react";

const ProvisioningSearch = ({ searchTerm, setSearchTerm }) => {
	const [typingTimeout, setTypingTimeout] = useState(null);

	const handleInputChange = (e) => {
		const newValue = e.target.value;

		// Clear the previous timeout to ensure only one timeout is active at a time
		if (typingTimeout) {
			clearTimeout(typingTimeout);
		}

		// Set a new timeout to update the search term after 500 milliseconds
		const newTimeout = setTimeout(() => {
			setSearchTerm(newValue);
		}, 500);

		// Update the typingTimeout state with the new timeout ID
		setTypingTimeout(newTimeout);
	};

	return (
		<div className="provisioning-search">
			<h2>Provision New Customer</h2>
			<InputText
				id="provisioningSearch"
				data={searchTerm}
				placeholder="Search CRM"
				maxLength={64} // Use number value for maxLength
				onChange={handleInputChange} // Use the handleInputChange function
			/>
		</div>
	);
};

export default ProvisioningSearch;
