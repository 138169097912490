import { useEffect, useState } from "react";
import { InputNumber } from "primereact/inputnumber";
import { Dropdown } from "primereact/dropdown";
import Row from "../../../components/Row";

const CustomerPackages = ({ packageItem, packageData, onChangeInput, setCanSubmit }) => {
	const EndUserPackageType = 1;
	const [showLevelAndSeats, setShowLevelAndSeats] = useState(packageItem && packageItem.EndUser);
	const [seats, setSeats] = useState(packageItem && packageItem.PUP ? packageItem.Seats : 1);
	const [errorMessage, setErrorMessage] = useState(""); // State for error message

	useEffect(() => {
		if (showLevelAndSeats) {
			// != (not !==) so we can prevent null or undefined, but allow 0.
			if (packageItem.Seats < seats) {
				setErrorMessage("Number of seats must be at least " + seats);
				setCanSubmit(false);
			}
			else {
				setErrorMessage("");
				setCanSubmit((packageItem.Id) && (packageItem.Level != null) && (packageItem.Seats));
			}
		}
		else {
			setCanSubmit(packageItem.Id !== null && packageItem.Id !== undefined);
		}
	}, [packageItem.Id, packageItem.Level, packageItem.Seats, seats, setCanSubmit]);

	const getSeatsLabel = (item) => {
		if ((o => {
			for (let k in o) {
				return false;
			}
			return true;
		})(item)) {
			return "";
		}
		return (item.PUP) ? "Total Seats (PUP)" : `Batches of ${item.Seats} (PCP)`;
	}

	const [selectedPackage, setSelectedPackage] = useState(null);
	const [seatsLabel, setSeatsLabel] = useState(getSeatsLabel(packageItem));

	const Levels = [
		{
			Id: 2,
			Value: "Gold"
		},
		{
			Id: 1,
			Value: "Silver"
		},
		{
			Id: 0,
			Value: "Bronze"
		}
	];

	// Handling the back button from the Summary...
	useEffect(() => {
		if (packageItem.Id) {
			const found = packageData.find((i) => { return i.ParentId === packageItem.Id; });
			if (found) {
				setSelectedPackage(found);
			}
		}
	}, []);

	const onChangePackage = (value) => {
		const endUser = value.PackageType === EndUserPackageType;
		setShowLevelAndSeats(endUser);
		setSeatsLabel(() => getSeatsLabel(value));
		setSeats(() => (value.PUP) ? value.Seats : 1);
		setSelectedPackage(value);
		if (endUser) {
			if (packageItem.EndUser) {
				onChangeInput({ packageItem: { ...packageItem, Id: value.ParentId, EndUser: true } });
			}
			else {
				onChangeInput({ packageItem: {
					...packageItem,
					Id: value.ParentId,
					EndUser: true,
					Level: null,
					Seats: null
				}
				});
			}
		}
		else {
			onChangeInput({ packageItem: {
				...packageItem,
				Id: value.ParentId,
				EndUser: false,
				Level: 0,
				Seats: 9999
			}});
		}
	}

	return (
		<div>
			<Row class="mb-4 w-6">
				<label className="col-4 align-self-center" htmlFor="package">Package</label>
				<Dropdown
					id="package"
					value={selectedPackage}
					onChange={(e) => onChangePackage(e.value)}
					options={packageData}
					optionLabel="DisplayName"
					placeholder="Select a package..."
				/>
			</Row>
			{showLevelAndSeats &&
				<>
					<Row class="mb-4 w-6">
						<label className="col-4 align-self-center" htmlFor="level">Level</label>
						<Dropdown
							id="level"
							value={packageItem.Level}
							onChange={(e) => onChangeInput({ packageItem: { ...packageItem, Level: e.value } })}
							dataKey="Id"
							options={Levels}
							optionLabel="Value"
							optionValue="Id"
							placeholder="Select a level..."
						/>
					</Row>
					{(seatsLabel.length > 0) &&
						<>
							<Row class="w-6 mb-2">
								<label className="col-4 align-self-center" htmlFor="seats">{seatsLabel}</label>
								<InputNumber
									id="seats"
									useGrouping={false}
									min={0}
									max={9999}
									onChange={e => onChangeInput({ packageItem: { ...packageItem, Seats: e.value } })}
									value={packageItem.Seats}
									placeholder={`${seats}-9999`}
								/>
							</Row>
							<Row class="w-6">
								<label className="col-4 align-self-center"></label>
								{errorMessage && <div className="error-text">{errorMessage}</div>}
							</Row>
						</>
					}
				</>
			}
		</div>
	);
};

export default CustomerPackages;