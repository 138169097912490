import Button, { ButtonType } from "../../components/Button";
import './LinkCard.scss'

const LinkCard = ({ children, ...props }) => {
	return (
		<div className="linkCard">
			<div className="linkCard_text">
				<p className="font_m_bold">{children}</p>
			</div>
			<div className="linkCard_link">
				<a href={props.link} download target="_blank" aria-label={props.buttonText} rel="noreferrer">
					<Button type={ButtonType.Primary} icon={props.icon} label={props.buttonText} />
				</a>
			</div>
		</div>
	);
}

export default LinkCard;