import { useState } from "react";
import { faCirclePlus } from "@fortawesome/free-solid-svg-icons";
import { formatDate } from "../../utils/formatting";
import { useSession, LogoutReason } from "../Auth/hooks/useSession";
import AuthLogoutMessage from "../Auth/components/AuthLogoutMessage";
import LoadingSpinner from "../../components/loadingSpinner/LoadingSpinner";
import DataTable from "../../components/DataTable";
import Button, { ButtonType } from "../../components/Button";
import useFetchAuto from "../../hooks/useFetchAuto";
import AddCustomer from "./AddCustomer";
import ViewCustomer from "./ViewCustomer";

const LisCustomers = () => {
	const { reason } = useSession();
	const [showAddModal, setAddModal] = useState(false);
	const [showViewModal, setViewModal] = useState(false);
	const [editing, setEditing] = useState(false);
	const [viewItemData, setViewItemData] = useState({});
	const [fetch, setFetch] = useState(true);
	const { loading, data } = useFetchAuto("/api/Customers/", null, fetch);

	function onClickCustomer(adding, item) {
		if (adding) {
			setAddModal(true);
		}
		else if (item.data.CompanyId) {
			setEditing(false);
			setViewItemData(item.data);
			setViewModal(true);
		}
	}

	const columns = [
		{
			field: "CompanyName",
			headerName: "Name",
			valueGetter: (data) => { return (data.CompanyId) ? data.CompanyName : <em className="grey">Provision pending:&nbsp;{data.CompanyName}</em>; }
		},
		{
			field: "Package",
			headerName: "Package"
		},
		{
			field: "Seats",
			headerName: "Seats"
		},
		{
			field: "Provider",
			headerName: "Provider"
		},
		{
			field: "CreationDate",
			headerName: "Date Created",
			valueGetter: (data) => { return formatDate(data.CreationDate); }
		}
	];

	return (
		<div>
			{(reason !== LogoutReason.None)
				? <AuthLogoutMessage reason={reason} />
				: (<>
					<h1>ucSKILLS Access</h1>
					<p className="mb-6">Give access to ucSKILLS to your customers or sub-resellers. Here you can provision users on ucSKILLS and set their offer.</p>
					{loading
						? <LoadingSpinner active={loading} />
						: (<DataTable columns={columns} rows={data} columnIdName="Id" onRowClick={(params) => onClickCustomer(false, params)} />)
					}
					<Button type={ButtonType.Secondary} icon={faCirclePlus} label={"Add New Customer"} onClick={() => onClickCustomer(true)} />
					{showAddModal && <AddCustomer showModal={showAddModal} setModal={setAddModal} setFetchParentData={setFetch} />}
					{showViewModal &&
						<ViewCustomer open={!editing} showModal={showViewModal} setModal={setViewModal} item={viewItemData} setFetchParentData={setFetch} />
					}
				</>)
			}
		</div>
	);
};

export default LisCustomers;