const PackageHeaderColumns = ({ editable, showSeatsAndPrices }) => {
	return (
		<div className={editable ? "package-row-editable" : "package-row"}>
			<p><strong>Name</strong></p>
			<p><strong>Type</strong></p>
			{showSeatsAndPrices &&
				<>
					<p><strong>Seats</strong></p>
					<p><strong>Bronze</strong></p>
					<p><strong>Silver</strong></p>
					<p><strong>Gold</strong></p>
				</>
			}
		</div>
	);
};

export default PackageHeaderColumns;