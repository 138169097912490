import { Outlet } from "react-router-dom";
import { InteractionType } from "@azure/msal-browser";
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import BetweenPages from "../features/Auth/components/BetweenPages";
import NavTop from "./navigation/NavTop";
import "/node_modules/primeflex/primeflex.css";

const Layout = () => {
	return (
		<>
			<MsalAuthenticationTemplate interactionType={InteractionType.Redirect} errorComponent={BetweenPages}>
				<NavTop />
				<main>
					<div className="gutters">
						<Outlet />
					</div>
				</main>
			</MsalAuthenticationTemplate>
		</>
	);
};

export default Layout;