import { useState } from "react";
import { faBoxesPacking, faPoundSign, faStar, faUsers, faFolderOpen } from "@fortawesome/free-solid-svg-icons";
import { getLoginType, getUserFullName, getUserResellerType, ResellerType } from "../../utils/getUserInformation";
import useLocalStorage from "../../hooks/useLocalStorage";
import { useSession, LogoutReason } from "../Auth/hooks/useSession";
import AuthLogoutMessage from "../Auth/components/AuthLogoutMessage";
import IconCard from "../../components/iconCard/IconCard";
import GetStartedNew from "./GetStartedNew";
import "./Home.scss";

const Home = () => {
	const { reason } = useSession();
	const [master, setMaster] = useState(true);
	const [showGetStartedNewModal, setGetStartedNewModal] = useState(false);
	const [user, setUser] = useState("");
	const [nonBillable, setNonBillable] = useState(false);
	const [reloadLocalStorage, setReloadLocalStorage] = useState(false);
	const reports = false;
	
	useLocalStorage("ap-liu", () => {
		if (getLoginType() === 1) {
			setGetStartedNewModal(true);
		}
		setUser(getUserFullName());
		setMaster(getUserResellerType() === ResellerType.iTEL);
		setNonBillable(getUserResellerType() === ResellerType.NonBillable);
	}, reloadLocalStorage);

	const description = (master)
		? "This site contains everything you need to manage your Resellers' access to the ucSKILLS digital onboarding platform, and review your billing. " +
			"Select one of the links below to get started."
		: "This site contains everything you need to provision your users on ucSKILLS, manage your Resellers' access to the ucSKILLS digital onboarding platform, " +
			"and review your billing. Select one of the links below to get started.";

	return (
		<div>
			<h1>Welcome {user}</h1>
			{(!showGetStartedNewModal && reason !== LogoutReason.None)
				? <AuthLogoutMessage reason={reason} />
				: (<>
					{showGetStartedNewModal && <GetStartedNew showModal={showGetStartedNewModal} setModal={setGetStartedNewModal} reload={setReloadLocalStorage} />}
					<p className="mb-6">{description}</p>
					<div className="mt-4 twoColGrid">
						{!master && <IconCard title="ucSKILLS Access" icon={faStar} link="/customers">
							Give access to ucSKILLS to your customers or sub-resellers. Here you can provision users on ucSKILLS and set their offer.
						</IconCard>}
						<IconCard title="My Resellers" icon={faBoxesPacking} link="/resellers">
							Give permission for your sub-resellers to sell on ucSKILLS training. This will give them access to this Admin Portal and let them provision ucSKILLS
							to their customers.
						</IconCard>
						<IconCard title="My Team" icon={faUsers} link="/teams">
							Manage your team - add or remove access to the Admin Portal for those who need it across your organisation.
						</IconCard>
						{!nonBillable && <IconCard title="My Billing" icon={faPoundSign} link="/billing">
							Download your monthly invoices and set an email address to receive them automatically. Dig into your sales figures and those of your resellers to
							understand your billing and performance.
						</IconCard>}
						{reports && <IconCard title="My Reports" icon={faFolderOpen} link="/reports">
							Get a detailed month by month report of your sales.
						</IconCard>}
						{
						master && <IconCard title="Provisioning" icon={faStar} link="/provisioning">
							Provision new ucSKILLS Companies from CRM data.
						</IconCard>
						}
					</div>
				</>)
			}
		</div>
	);
};

export default Home;