import { useEffect } from "react";

/*
 * This hook is purely meant to listen for the custom "ap-storage" events, which should be fired whenever custom localStorage data is added.
 * This custom data is usually added as a result of a server request.
 */
const useLocalStorage = (key, execute, reload = false) => {
	useEffect(() => {
		if (localStorage.getItem(key)) {
			execute();
		}
		else {
			window.addEventListener("ap-storage", execute, false);
		}
		return () => {
			window.removeEventListener("ap-storage", execute);
		}
	}, [reload]);
};

export default useLocalStorage;