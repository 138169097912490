import { useEffect } from "react";
import BusinessInputs from "../../../components/BusinessInputs";
import UserInputs from "../../../components/UserInputs";

const CustomerDetails = ({ businessName, businessAddress, userFirstName, userLastName, userEmail, onChangeInput, setCanSubmit, title }) => {
	useEffect(() => {
		setCanSubmit(
			(businessName.value.trim().length > 0) &&
			(businessAddress.value.trim().length > 0) &&
			(userFirstName.value.trim().length > 0) &&
			(userLastName.value.trim().length > 0) &&
			(userEmail.value.trim().length > 0));
	}, [businessName.value, businessAddress.value, userFirstName.value, userLastName.value, userEmail.value, setCanSubmit]);

	return (
		<div>
			{(!title.startsWith("Edit")) && <p>Please add the details below for your new Customer.</p>}
			<BusinessInputs
				nameLabel="Business Name"
				name={businessName}
				addressLabel="Business Address"
				address={businessAddress}
				onChangeInput={onChangeInput}
			/>
			<UserInputs 
				nameLabel="Primary Contact"
				firstName={userFirstName}
				lastName={userLastName}
				emailLabel="Primary Contact Email"
				email={userEmail}
				onChangeInput={onChangeInput}
			/>
		</div>
	);
};

export default CustomerDetails;