import { getB2CUserFlowsAndPolicies, getReadScope, getScopes } from "./configuration.js";
import { getFetchOptions } from "../../utils/fetch.js";

export async function loginSuccessEventCallback(instance) {
	const account = instance.getActiveAccount();
	const options = getFetchOptions({
		method: "POST"
	});
	const response = await instance.acquireTokenSilent({
		scopes: getReadScope(),
		account: account
	});
	options.headers.Authorization = "Bearer " + response.accessToken;

	/*
	 * An existing storage item should not exist on login.
	 * This may occur on the first ap-liu write, which can take longer than the Home page to run the useSession hook.
	 */
	if (localStorage.getItem("ap-liu")) {
		localStorage.removeItem("ap-liu");
	}

	function ok(text) {
		localStorage.setItem("ap-liu", text);	
		window.dispatchEvent(new Event("ap-storage"));

		const item = JSON.parse(text);
		const key = "ap-lau-" + btoa(item.Email);
		const lau = localStorage.getItem(key);
		if (lau) {
			window.location.href = lau;
			localStorage.removeItem(key);
		}
	}

	function notFound(error) {
		if (error) {
			console.error(error);
		}
		let signInFlowRequest = {
			authority: getB2CUserFlowsAndPolicies().authorities.signIn.authority,
			scopes: getScopes()
		};
		instance.loginRedirect(signInFlowRequest);
	}

	const result = await fetch("/api/Login/", options);
	switch (result.status) {
		case 200:
			ok(await result.text());
			break;
		case 404:
			notFound(await result.json());
			break;
		default:
			break;
	}
}