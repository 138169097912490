/**
 * @returns {string} Full name of logged in User.
 */
export function getUserFullName() {
	const item = JSON.parse(localStorage.getItem("ap-liu"));
	return (item) ? item.FirstName + " " + item.LastName : "";
}

/**
 * @returns {string} Email address of logged in User.
 */
export function getUserEmail() {
	const item = JSON.parse(localStorage.getItem("ap-liu"));
	return (item) ? item.Email : "";
}


/**
 * @returns {string} Reseller name of logged in User.
 */
export function getUserResellerName() {
	const item = JSON.parse(localStorage.getItem("ap-liu"));
	return (item) ? item.ResellerName : "";
}

/**
 * @readonly
 * @enum {number}
 */
export const ResellerType = {
	iTEL: 0,
	Billable: 1,
	NonBillable: 2
};

/**
 * @returns {number} Reseller Type of logged in User.
 */
export function getUserResellerType() {
	const item = JSON.parse(localStorage.getItem("ap-liu"));
	return (item) ? item.ResellerType : -1;
}

/**
 * @returns {boolean} Login Type of logged in User.
 */
export function getLoginType() {
	const item = JSON.parse(localStorage.getItem("ap-liu"));
	return (item) ? item.LoginType : -1;
}

/**
 * @returns {boolean} Timestamp of logged in User.
 */
export function getTimestamp() {
	const item = JSON.parse(localStorage.getItem("ap-liu"));
	return (item) ? item.Timestamp : -1;
}

export function updateTimestamp(value) {
	let item = JSON.parse(localStorage.getItem("ap-liu"));
	item = {
		...item,
		Timestamp: value
	};
	localStorage.setItem("ap-liu", JSON.stringify(item));
}