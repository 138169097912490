import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleArrowUp } from "@fortawesome/free-solid-svg-icons";
import CustomerBilling from "./CustomerBilling";
import { formatAsCurrency } from "../../../utils/formatting";

const ResellerBilling = ({ resellers, nested, pup, parentIndex = '', nestingLevel = 1 }) => {
	const [activeNestedAccordion, setActiveNestedAccordion] = useState({});

	const toggleNestedAccordion = (index) => {
		setActiveNestedAccordion(prev => ({
			...prev,
			[index]: !prev[index]
		}));
	};

	return (
		<>
			{!nested && (
				<div className="billing-header">
					<p className="font_s_bold">Seller</p>
					<p className="font_s_bold">Customers</p>
					<p className="font_s_bold">Pricing</p>
					<p className="font_s_bold">Quantity</p>
					<p className="font_s_bold">Cost</p>
				</div>
			)}
			{resellers.map((item, index) => {
				const currentIndex = parentIndex ? `${parentIndex}-${index}` : `${index}`;
				const leftMargin = (nestingLevel - 1) * 30;

				return (
					<div key={currentIndex}>
						<div className={`billing-reseller-grid ${activeNestedAccordion[currentIndex] ? 'active' : ''} ${(item.Customers && item.Customers.length > 0) || (item.Resellers && item.Resellers.length > 0) ? 'pointer' : ''}`}
							style={{ marginLeft: `${leftMargin}px` }}
							onClick={() => toggleNestedAccordion(currentIndex)}>
							<h4>
								{item.Customers && item.Customers.length > 0 && <FontAwesomeIcon icon={faCircleArrowUp} />}
								{item.Resellers && item.Resellers.length > 0 && <FontAwesomeIcon icon={faCircleArrowUp} />}
								{item.Name}
							</h4>
							<p>{item.CustomerCount}</p>
							<p>{formatAsCurrency(item.Pricing)}</p>
							<p>{item.Quantity}</p>
							<p>{formatAsCurrency(item.Cost)}</p>
						</div>
						{activeNestedAccordion[currentIndex] && (
							<div>
								{item.Customers && <CustomerBilling customers={item.Customers} nestingLevel={nestingLevel + 1} pricing={item.Pricing} pup={pup} batchSize={item.BatchSize} />}
								{item.Resellers && <ResellerBilling resellers={item.Resellers} nested={true} pup={pup} parentIndex={currentIndex} nestingLevel={nestingLevel + 1} />}
							</div>
						)}
					</div>
				);
			})}
		</>
	);
};

export default ResellerBilling;