import { useEffect } from "react";
import { Checkbox } from "primereact/checkbox";

const MemberConfirmation = ({ userFirstName, userLastName, userEmail, confirmation, onChangeInput, setCanSubmit }) => {
	useEffect(() => {
		setCanSubmit(confirmation);
	}, [confirmation, setCanSubmit]);

	return (
		<div>
			<p>
				This will send an email to <strong>{userFirstName.value} {userLastName.value}</strong> ({userEmail.value}), inviting them to join this Admin Portal with the same access level as you.
			</p>
			<p>
				Please make sure these details are correct and that the recipient is expecting this email.
			</p>
			<div id="confirm-item">
				<Checkbox inputId="confirm" checked={confirmation} onChange={e => onChangeInput({ confirmation: !confirmation })} />
				<label htmlFor="confirm" className="ml-2">I confirm that the Team Member I am creating has permission to view and edit all the details within this Admin Portal</label>
			</div>
		</div>
	);
};

export default MemberConfirmation;