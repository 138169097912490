import { useState } from "react";
import { faArrowRight, faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { useSession, LogoutReason } from "../Auth/hooks/useSession";
import AuthLogoutMessage from "../Auth/components/AuthLogoutMessage";
import { isNameValid, isEmailValid } from "../../utils/validation";
import { getFetchOptions } from "../../utils/fetch";
import useFetch from '../../hooks/useFetch';
import useMultiStepModal from "../../components/modals/hooks/useMultiStepModal";
import MultiStepModal from "../../components/modals/MultiStepModal";
import MemberDetails from "./components/MemberDetails";
import MemberConfirmation from "./components/MemberConfirmation";
import MessageModal from "../../components/modals/MessageModal";
import LoadingSpinner from "../../components/loadingSpinner/LoadingSpinner";
import Button, { ButtonType } from "../../components/Button";

const AddMember = ({ showModal, setModal, setFetchParentData }) => {
	const INITIAL_DATA = {
		userFirstName: { value: "", validation: 0 },
		userLastName: { value: "", validation: 0 },
		userEmail: { value: "", validation: 0 },
		confirmation: false
	};
	
	const { reason } = useSession();
	const toggle = () => setModal(!showModal);
	const [data, setData] = useState(INITIAL_DATA);
	const [canSubmit, setCanSubmit] = useState(false);
	const { execute, executingFetch, error } = useFetch();

	const { contents, content, step, back, next, last } = useMultiStepModal([
		<MemberDetails {...data} onChangeInput={onChangeInput} setCanSubmit={setCanSubmit} title={"Team Member Details"} />,
		<MemberConfirmation {...data} onChangeInput={onChangeInput} setCanSubmit={setCanSubmit} title={"Confirm and Send Email"} />
	]);

	function onChangeInput(fields) {
		setData((prev) => {
			return { ...prev, ...fields }
		});
	}

	function onClickNext() {
		const validations = [
			isNameValid(data.userFirstName.value) ? 2 : 1,
			isNameValid(data.userLastName.value) ? 2 : 1,
			isEmailValid(data.userEmail.value) ? 2 : 1
		];
		setData((prev) => {
			return {
				...prev,
				userFirstName: { value: prev.userFirstName.value, validation: validations[0] },
				userLastName: { value: prev.userLastName.value, validation: validations[1] },
				userEmail: { value: prev.userEmail.value, validation: validations[2] }
			};
		});
		const valid = validations.every((value) => value === 2);
		if (valid) {
			next();
		}
	}

	function onClickSubmit(enable) {
		setFetchParentData(false);
		const options = getFetchOptions({
			method: "POST",
			body: {
				B2CId: "00000000-0000-0000-0000-000000000000",
				FirstName: data.userFirstName.value,
				LastName: data.userLastName.value,
				Email: data.userEmail.value
			}
		});
		execute("/api/Member", options, (error) => {
			if (error) {
				console.error(error.message);
			}
			else {
				toggle();
				setFetchParentData(true);
			}
			enable();
		});
	}

	const modalButtons = (
		<>
			{(!last) ? (
				<Button type={ButtonType.Primary} disabled={!canSubmit} icon={faArrowRight} iconRight label={"Next"} onClick={onClickNext} />
			) : (
					<Button
						type={ButtonType.Primary}
						disabled={!canSubmit}
						icon={faCircleCheck}
						label={"Confirm and Send Welcome Email"}
						onClick={(e, c) => onClickSubmit(c)}
						disableUntilClickCompleted={true}
					/>
			)}
		</>
	);

	function onCloseErrorModal() {
		window.location.reload();
	}

	const ErrorModal = (
		<MessageModal title="Cannot create Team Member" onClick={onCloseErrorModal} size="m">
			<p ref={(element) => { element.innerText = error.message; }}/>
		</MessageModal>
	);

	const AddModal = (
		<MultiStepModal
			title="Add New Team Member"
			showModal={showModal}
			steps={contents.length}
			step={step}
			content={content}
			size="m"
			modalButtons={modalButtons}
			back={back}
			close={toggle}
		/>
	);

	const render = () => {
		if (reason !== LogoutReason.None) {
			return <AuthLogoutMessage reason={reason} />;
		}
		if (executingFetch) {
			return <LoadingSpinner active={executingFetch} />;
		}
		if (error) {
			return ErrorModal;
		}
		return AddModal;
	}

	return (
		<>{render()}</>
	);
};

export default AddMember;